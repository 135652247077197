<template>
  <div class="register-form">
    <template v-if="registered">
      <h1 class="super-title3">{{ $t("register.confirmation.title") }}</h1>
      <div class="register-description text6">
        <FontIcon class="register-check" name="ui_done" />
        <p v-html="$t('register.confirmation.text', { firstname })"></p>
      </div>
      <p>
        <a
          v-if="!options.disableEmailVerification"
          class="link1"
          id="link--register-back"
          @click="$emit('resend', { email: username })"
          >{{ $t("register.links.spam") }}</a
        >
      </p>
    </template>
    <template v-else>
      <b-container>
        <b-row>
          <b-col>
            <h1 class="super-title3">{{ $t("register.title") }}</h1>
            <p class="register-description text6">
              {{ $t("register.instructions") }}
            </p>
          </b-col>
        </b-row>
      </b-container>

      <DynamicForm
        v-if="form"
        :form="form"
        :buttonText="buttonText"
        :submitting="submitting"
        prefixInputId="register"
        @submit="onSubmit"
      >
        <template slot="header">
          <Alert variant="error" v-if="error" id="alert--register-error">
            {{ $t(`register.error.${error}.pre`) }}
            <a
              id="link--register-error"
              class="text7"
              style="color:#fff"
              @click="$root.$emit(`register.links.error.${error}:click`)"
              >{{ $t(`register.error.${error}.link`) }}</a
            >
            {{ $t(`register.error.${error}.post`) }}
          </Alert>
        </template>
      </DynamicForm>
      <div class="mb-4 text-center text8" v-if="needHelpModule">
        {{ $t("register.links.help.pre") }}
        <a
          id="link--register-need-help"
          class="text7"
          @click="$root.$emit('register.links.help:click')"
        >
          {{ $t("register.links.help.link") }}</a
        >
        {{ $t("register.links.help.post") }}
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.register-form {
  h1 {
    @include setColor(--color-title1);
  }

  .register-description {
    display: flex;
    flex-direction: row;
    @include setColor(--color-text);

    .register-check {
      color: var(--color-status-success);
      margin-right: rem(8px);
      line-height: inherit;
      font-size: 150%;
    }
  }

  a {
    @include setColor(--color-link);
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DynamicForm from "@/components/DynamicForm";
import formMixin from "@/mixins/formMixin";
import { Alert, FontIcon } from "@johnpaul/jp-vue-components";
import { getConfig } from "@/store";
import { getModuleConfig } from "@/utilities/config";

export default {
  name: "RegisterForm",
  mixins: [formMixin],

  components: {
    Alert,
    DynamicForm,
    FontIcon,
  },

  data() {
    const config = getConfig();
    const { options } = getModuleConfig(config, "register");
    return {
      success: false,
      memberTokensFetching: false,
      memberTokensError: false,
      registerMemberError: false,
      form: null,
      buttonText: this.$t("register.form.submit"),
      options,
    };
  },

  computed: {
    ...mapState({
      submitting: state => state.register.registering,
      registered: state => state.register.registered,
      error: state => state.register.registerError,
      firstname: state => state.register.firstname,
      username: state => state.register.username,
    }),

    needHelpModule() {
      return getModuleConfig(getConfig(), "need-help");
    },
  },

  methods: {
    ...mapActions({
      register: "register/register",
    }),
    ...mapMutations({
      reset: "register/reset",
    }),

    async onSubmit(apiValues) {
      const requestStatus = await this.register({
        attributes: apiValues,
      });

      if (requestStatus.success) {
        this.$root.$emit("register:success");
      } else {
        this.$root.$emit("register:error", {
          code: requestStatus.code,
        });
      }
    },
  },

  async mounted() {
    await this.loadFormConfig("registerForm");
    this.reset();
    this.$root.$emit("register:mounted");
  },
};
</script>
