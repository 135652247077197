import jwtDecode from "jwt-decode";
import client, { setHeadersAndInterceptors } from "@/plugins/jp-api-client";
import { getConfig } from "@/store";
import { getErrorCode } from "@/utilities/errors";

export default {
  namespaced: true,

  state: {
    authenticated: null,

    accessToken: null,
    refreshToken: null,
    refreshTokenExpiration: null,
    salesforce: null,

    accessTokenClient: null,
    fetchingClientToken: false,
    fetchClientTokenError: null,

    fetchingGaumontToken: false,
    fetchGaumontTokenError: null,

    verifyingAccount: false,
    verifyAccountSuccess: false,
    verifyAccountError: null,

    revoking: false,
    revokeError: null,

    fingerprint: null,
    fillingFingerprintAuthorization: false,
  },

  mutations: {
    setTokens(state, { accessToken = null, refreshToken = null } = {}) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      if (accessToken) {
        const jwt = jwtDecode(state.accessToken);
        state.salesforce = jwt["external-ids"]?.[0]?.["id-salesforce"];
      }
      setHeadersAndInterceptors(accessToken);
    },

    setRefreshTokenExpiration(state, refreshTokenExpiration = null) {
      state.refreshTokenExpiration = refreshTokenExpiration;
    },

    setAuthenticated(state) {
      state.authenticated = true;
      setHeadersAndInterceptors(true, true);
    },

    revokeSession(state) {
      state.authenticated = false;
      setHeadersAndInterceptors(false, true);
    },

    resetAccessToken(state) {
      state.accessToken = null;
    },

    resetRefreshToken(state) {
      state.refreshToken = null;
    },

    revokeTokensStart(state) {
      state.revoking = true;
      state.revokeError = null;
    },

    revokeTokensSuccess(state) {
      state.revoking = false;
    },

    revokeTokensError(state, error) {
      state.revoking = false;
      state.revokeError = error;
    },

    setClientToken(
      state,
      { accessTokenClient = null, withProxy = false } = {},
    ) {
      state.accessTokenClient = accessTokenClient;
      setHeadersAndInterceptors(accessTokenClient, withProxy);
    },

    fetchClientTokenStart(state) {
      state.fetchingClientToken = true;
      state.fetchingClientTokenError = null;
    },

    fetchClientTokenSuccess(state) {
      state.fetchingClientToken = false;
    },

    fetchClientTokenError(state, error) {
      state.fetchingClientToken = false;
      state.fetchClientTokenError = error;
    },

    fetchGaumontTokenStart(state) {
      state.fetchingGaumontToken = true;
      state.fetchingGaumontTokenError = null;
    },

    fetchGaumontTokenSuccess(state) {
      state.fetchingGaumontToken = false;
    },

    fetchGaumontTokenError(state, error) {
      state.fetchingGaumontToken = false;
      state.fetchGaumontTokenError = error;
    },

    verifyAccountStart(state) {
      state.verifyingAccount = true;
      state.verifyAccountSuccess = false;
      state.verifyAccountError = null;
    },

    verifyAccountError(state, error) {
      state.verifyingAccount = false;
      state.verifyAccountSuccess = false;
      state.verifyAccountError = error;
    },

    verifyAccountSuccess(state) {
      state.verifyingAccount = false;
      state.verifyAccountSuccess = true;
    },

    startFillingFingerprintAuthorization(state) {
      state.fillingFingerprintAuthorization = true;
    },

    stopFillingFingerprintAuthorization(state) {
      state.fillingFingerprintAuthorization = false;
    },

    enableFingerprint(state) {
      state.fingerprint = true;
    },

    disableFingerprint(state) {
      state.fingerprint = false;
    },

    resetFingerprint(state) {
      state.fingerprint = null;
    },

    setSalesforce(state, sf) {
      state.salesforce = sf;
    },
  },

  getters: {
    loggedIn(state, _getters, rootState) {
      return (
        (rootState.config.env.proxy && state.authenticated) ||
        (!!state.accessToken && !state.fillingFingerprintAuthorization)
      );
    },
  },

  actions: {
    async logout({ commit, rootState, dispatch }) {
      await dispatch("revokeTokens");
      commit("setSalesforce", null);
      if (!rootState.isMobileApp) {
        return true;
      }
      await dispatch("fetchClientToken");
      const { success: configSuccessfullyRetrieved } = await dispatch(
        "config/fetchConfig",
        {},
        { root: true },
      );
      return configSuccessfullyRetrieved;
    },
    async revokeTokens({ commit, state, rootState }) {
      commit("revokeTokensStart");
      const success = await client.auth.revoke(
        getConfig().env.clientId,
        getConfig().env.clientSecret,
        rootState.config.env.proxy ? "" : state.accessToken,
      );
      if (success) {
        commit("setClientToken");
        rootState.config.env.proxy
          ? commit("revokeSession")
          : commit("setTokens");
        commit("setRefreshTokenExpiration");
        commit("revokeTokensSuccess");
        commit("member/update", null, { root: true });
        commit("subscriptions/update", null, { root: true });
        commit("passes/resetPkPass");
      } else {
        commit("revokeTokensError");
      }
      return success;
    },
    async fetchClientToken({ commit, state, rootState }) {
      if (!rootState.config.env.proxy && state.accessTokenClient) {
        // Check access token expiration date
        const jwt = jwtDecode(state.accessTokenClient);
        if (Date.now() < jwt.exp * 1000) {
          return Promise.resolve({
            success: true,
            access_token_client: state.accessTokenClient,
          });
        }
      }
      commit("fetchClientTokenStart");

      try {
        const response = await client.auth.token(
          getConfig().env.clientId,
          getConfig().env.clientSecret,
          {
            grant_type: "client_credentials",
          },
        );

        commit("setClientToken", {
          accessTokenClient: response.data.access_token,
          withProxy: rootState.config.env.proxy,
        });

        commit("fetchClientTokenSuccess");

        return {
          success: true,
          access_token_client: state.accessTokenClient,
        };
      } catch (error) {
        commit("fetchClientTokenError", getErrorCode(error));

        return {
          success: false,
        };
      }
    },
    verifyAccount({ commit }, token) {
      commit("verifyAccountStart");

      return client.auth.verification
        .verifyAccount(token)
        .then(() => {
          commit("verifyAccountSuccess");

          return true;
        })
        .catch(error => {
          commit("verifyAccountError", getErrorCode(error));

          return false;
        });
    },
    async fetchGaumontToken({ commit, state }) {
      commit("fetchGaumontTokenStart");

      try {
        const response = await client.auth.gaumontToken(state.accessToken);

        commit("fetchGaumontTokenSuccess");

        return {
          success: true,
          gaumont_token: response.data.token,
        };
      } catch (error) {
        commit("fetchGaumontTokenError", getErrorCode(error));

        return {
          success: false,
        };
      }
    },
  },

  persistPaths: [
    "auth.authenticated",
    "auth.accessToken",
    "auth.refreshToken",
    "auth.refreshTokenExpiration",
    "auth.fingerprint",
    "auth.salesforce",
  ],
};
