<template>
  <div class="reset-password">
    <template v-if="showConfirmation">
      <h1 class="super-title3">
        {{ $t("password.reset.confirmation.title") }}
      </h1>
      <p class="reset-password-description text6">
        <FontIcon name="ui_done" />
        {{ $t("password.reset.confirmation.text") }}
      </p>
      <p>
        <a
          class="text5"
          id="link--password-reset-back"
          @click="$root.$emit('password.reset.confirmation.links.back:click')"
        >
          {{ $t("password.reset.confirmation.links.back") }}
        </a>
      </p>
    </template>

    <template v-else>
      <h1 class="super-title3">{{ $t("password.reset.title") }}</h1>
      <p class="reset-password-description text6">
        {{ $t("password.reset.instructions") }}
      </p>

      <form @submit.prevent="onSubmit" novalidate>
        <Alert
          variant="error"
          v-if="resetError"
          id="alert--password-reset-error"
        >
          <span v-html="$t(`password.reset.errors.${resetError}`)"></span>
        </Alert>

        <p v-if="question" class="reset-password-question text5">
          {{ question }}
        </p>

        <TextArea
          v-if="question"
          id="input--password-reset-answer"
          :disabled="resetting"
          :label="$t('password.reset.inputs.answer')"
          :value.sync="answer"
          :error="errors.answer"
          :max-length="100"
          rows="2"
          @blur="checkAnswer"
        />

        <TextInput
          id="input--password-reset-password"
          type="password"
          :disabled="resetting"
          :label="$t('password.reset.inputs.password')"
          :value.sync="password"
          @blur="checkPassword"
          :error="errors.password"
        />

        <TextInput
          id="input--password-reset-password-confirmation"
          type="password"
          :disabled="resetting"
          :label="$t('password.reset.inputs.passwordConfirmation')"
          :value.sync="passwordConfirmation"
          :error="errors.passwordConfirmation"
          @blur="checkPasswordConfirmation"
          @update:value="errors.passwordConfirmation = null"
        />

        <TextValidator
          :title="$t('password.reset.form.inputs.policies.new-password')"
          :value="password"
          :rules="passwordPolicies"
        />

        <ActivityIndicator v-if="resetting" />

        <Button
          v-else
          nativeType="submit"
          :disabled="!canBeSubmitted"
          id="button--password-reset-submit"
          >{{ $t(`password.reset.buttons.submit`) }}</Button
        >
      </form>
    </template>
  </div>
</template>

<style lang="scss">
.reset-password {
  &-question {
    @include setColor(--color-text);
  }

  h1 {
    @include setColor(--color-title1);
  }

  .reset-password-description {
    @include setColor(--color-text);
  }

  #link--password-reset-back {
    color: var(--color-link);
  }
}
</style>

<script>
import formMixin from "@/mixins/formMixin";

import { mapActions, mapState } from "vuex";

import {
  ActivityIndicator,
  Alert,
  Button,
  FontIcon,
  TextArea,
  TextInput,
  TextValidator,
} from "@johnpaul/jp-vue-components";

import { isString, isStrongPassword } from "@/utilities/validators";

export default {
  name: "ResetPassword",

  mixins: [formMixin],

  components: {
    ActivityIndicator,
    Alert,
    Button,
    FontIcon,
    TextArea,
    TextInput,
    TextValidator,
  },

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      question: null,
      questionId: null,
      answer: null,
      password: null,
      passwordConfirmation: null,

      errors: {
        answer: null,
        password: null,
        passwordConfirmation: null,
      },

      showConfirmation: false,

      policy: {
        text: this.$t("password.reset.form.inputs.policies.new-password"),
        type: "password",
      },
    };
  },

  computed: {
    ...mapState({
      config: state => state.config,
      resetting: state => state.password.resetting,
      resetError: state => state.password.resetError,
      secretQuestion: state => state.password.secretQuestion,
    }),
    canBeSubmitted() {
      return this.questionId
        ? this.answer && this.isValidPassword
        : this.isValidPassword;
    },
    isValidPassword() {
      return (
        this.password &&
        !this.errors.password &&
        this.passwordConfirmation &&
        !this.errors.passwordConfirmation &&
        this.password === this.passwordConfirmation &&
        isStrongPassword(this.password)
      );
    },
    passwordPolicies() {
      return this.config?.options.policies.password;
    },
  },

  watch: {
    secretQuestion(value) {
      this.questionId = value;
      this.question = this.$t(`common.secret.question.option.${value}`);
    },
  },

  methods: {
    ...mapActions({
      reset: "password/reset",
      getSecretQuestion: "password/getSecretQuestion",
    }),

    checkAnswer() {
      this.checkField("answer", {
        required: true,
      });
    },

    checkPassword() {
      this.checkField("password", {
        required: true,
      });
      if (isString(this.passwordConfirmation)) {
        this.checkPasswordConfirmation();
      }
    },

    checkPasswordConfirmation() {
      if (!isString(this.passwordConfirmation)) {
        this.errors.passwordConfirmation = this.$t(
          "common.form.input.error.missing",
        );
      } else if (this.password !== this.passwordConfirmation) {
        this.errors.passwordConfirmation = this.$t(
          "password.reset.passwordConfirmation.invalid",
        );
      } else {
        this.errors.passwordConfirmation = null;
      }
    },

    async onSubmit() {
      this.checkPassword();
      this.checkPasswordConfirmation();

      if (!this.canBeSubmitted) {
        return;
      }

      const { success } = await this.reset({
        token: this.token,
        password: this.password,
        question: this.questionId ? this.questionId : null,
        answer: this.questionId ? this.answer : null,
      });

      if (success) {
        this.showConfirmation = true;
        this.$root.$emit("reset-password:success");
      }
    },
  },

  async mounted() {
    await this.getSecretQuestion(this.token);
  },
};
</script>
