<script>
import { mapState } from "vuex";
import { setCookieConsent } from "@johnpaul/jp-tracker";
import { cookieManager } from "@/utilities/cookies";
import { getModuleConfig } from "@/utilities/config";
import { requestIOSAuthorization } from "@/utilities/cordova";

const ONETRUST = "onetrust";

export default {
  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      config: state => state.config,
    }),

    moduleOptions() {
      return this.config ? getModuleConfig(this.config, "cookie-banner").options : {};
    },
  },
  methods: {
    setATConsent(provider, event) {
      if (provider === ONETRUST) {
        const categoriesConsent = cookieManager.getCategoriesConsent(
          event.detail,
          this.moduleOptions?.trackingCookieIndex,
        );
        setCookieConsent(categoriesConsent);
      } else {
        if (event.detail?.trigger === "ok")
          setCookieConsent(
            event.detail?.consent?.[this.moduleOptions?.trackingCookieIndex],
          );
        localStorage.setItem(
          this.moduleOptions?.consentCookieName,
          event.detail?.consent_string,
        );
      }
    },
    setBanner() {
      if (this.moduleOptions?.defaultConsentCookie) {
        setCookieConsent(false);
        return;
      }
      const { consentCookieName, cmp } = this.moduleOptions;

      let cookieValue = cookieManager.getCookie(cmp, consentCookieName);
      if (!cookieValue) {
        cookieValue = localStorage.getItem(
          this.moduleOptions?.consentCookieName,
        );
      }

      if (cookieValue) {
        cookieManager.checkCategories(
          cookieValue,
          this.moduleOptions?.trackingCookieIndex,
          setCookieConsent,
        );
      }

      if (document.getElementById("wscrconsent")) return;

      const {
        scriptUrls,
        dataDomainScripts,
        useAppLanguage,
      } = this.moduleOptions;
      const dataDomainScript =
        process.env.VUE_APP_ENVIRONMENT !== "production"
          ? dataDomainScripts?.test
          : dataDomainScripts?.prod;
      // get src script
      let src =
        scriptUrls[this.locale] ||
        scriptUrls[this.config?.options.defaultLocale];

      cookieManager.install(
        cmp,
        src,
        {
          "data-document-language": useAppLanguage,
          "data-domain-script": dataDomainScript,
        },
        this.setATConsent,
      );
    },
  },

  mounted() {
    // request IOS tracking authorization
    if (window.usingCordova)
      document.addEventListener(
        "deviceready",
        async () => {
          if (window.cordova.platformId === "ios") {
            try {
              const response = await requestIOSAuthorization();
              if (response.success) {
                return setCookieConsent(response.result);
              }
            } catch {
              this.setBanner();
            }
          } else this.setBanner();
        },
        false,
      );
    else this.setBanner();
  },

  beforeDestroy() {
    document.removeEventListener("wscr.consent", this.setATConsent);
  },

  render() {
    return "";
  },
};
</script>
