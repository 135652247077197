<template>
  <div :class="['connection-buttons', transparent && 'transparent']">
    <Button
      v-if="loginConfig"
      class="login-button"
      type="secondary"
      id="link--header-login"
      @click.native="$root.$emit('header.buttons.login:click')"
    >
      {{ $t("common.header.buttons.login") }}
    </Button>
    <Button
      v-if="registerConfig"
      type="primary"
      id="link--header-register"
      @click.native="$root.$emit('header.buttons.register:click')"
    >
      {{ $t("common.header.buttons.register") }}
    </Button>
  </div>
</template>

<style lang="scss">
.connection-buttons {
  padding: rem(20px 0 35px);
  display: flex;
  flex-direction: column-reverse;

  button,
  button:hover {
    height: rem(40px);
  }

  .login-button {
    margin-top: rem(20px);
  }

  @include respond-min("xlarge") {
    padding: 0;
    flex-direction: row;
    padding-right: rem(43px);

    button {
      min-width: 120px;
      margin: 0;
    }
  }

  @include respond-min("xlarge") {
    .login-button,
    .login-button:hover {
      border: none;
      margin: 0 rem(20px);
    }

    &.transparent {
      .login-button,
      .login-button:hover {
        @include setColor(--color-cta-negative);
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { getModuleConfig } from "@/utilities/config";
import { Button } from "@johnpaul/jp-vue-components";

export default {
  components: {
    Button,
  },

  data() {
    return {
      navbarOpened: false,
    };
  },

  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      config: state => state.config,
    }),
    loginConfig() {
      return this.config ? getModuleConfig(this.config, "login") : null;
    },
    registerConfig() {
      return this.config ? getModuleConfig(this.config, "register") : null;
    },
  },
};
</script>
