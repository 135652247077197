<template>
  <div class="pages-nav">
    <EventLink
      v-for="link in links"
      :key="link"
      :id="`link--header-link-${link}`"
      :event="getEventNameFromLink(link)"
      :slug="getEventSlugFromLink(link)"
      class="nav-item text6"
    >
      {{ $t(`common.menu.links.${link}`) }}
    </EventLink>
  </div>
</template>

<style lang="scss">
.pages-nav {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;

  .nav-item {
    margin-top: rem(14px);
    text-transform: uppercase;
    text-align: left;
    color: inherit;
  }

  .nav-item.active,
  .nav-item.router-link-active {
    @include setColor(--color-selection);
    font-weight: bold;

    &::after {
      opacity: 1;
    }
  }

  @include respond-min("xlarge") {
    display: inline-block;
    position: absolute;
    width: auto;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    margin-top: 0;

    .nav-item {
      height: rem(72px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 rem(15px);
      position: relative;
      text-decoration: none;

      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        @include setColor(--color-selection, border-bottom-color);
        opacity: 0;
        transition: 200ms opacity ease;
        content: " ";
      }
    }

    .active::after,
    .router-link-active::after {
      opacity: 1;
    }
  }
}
</style>

<script>
import EventLink from "@/components/EventLink";
import { FEATURES, isFeatureEnabled } from "@/utilities/features";
import { getConfig } from "@/store";

export default {
  components: {
    EventLink,
  },

  data() {
    return {
      links: getConfig().options.headerLinks,
    };
  },

  computed: {
    isAthenaEnabled() {
      return isFeatureEnabled(FEATURES.ATHENA);
    },
  },

  methods: {
    getEventNameFromLink(link) {
      return this.isAthenaEnabled
        ? "menu.links.dynamic-route:click"
        : `menu.links.${link}:click`;
    },
    getEventSlugFromLink(link) {
      return this.isAthenaEnabled ? link : undefined;
    },
  },
};
</script>
