<template>
  <ActivityIndicator v-if="fetching && isOnline" />
  <div
    id="app"
    :class="[
      'd-flex',
      'flex-column',
      'position-relative',
      (isRootedDevice || isWindowFrameBusted) && 'lock-scroll',
    ]"
    v-else
  >
    <portal-target name="portal-root" class="portal-root"></portal-target>

    <Header
      @logout="onLogout"
      :shadow="headerShadow"
      :transparent="transparent"
    />
    <router-view
      class="jp-content"
      :key="componentKey"
      @header="updateHeader"
    />
    <Footer />

    <!-- Append widget modules -->
    <component
      v-for="widget in enabledWidgets"
      :key="widget.name"
      :is="widget.name"
      @overflow="setOverflow"
    />

    <AppUpdate v-if="newAppUrl" :url="newAppUrl" />

    <RootedDevice v-if="isRootedDevice" />

    <FrameBustedWindow v-if="isWindowFrameBusted" />

    <Error type="network" v-if="showNetworkError" />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Primary, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  > .jp-content {
    position: relative;
  }

  &.lock-scroll {
    max-height: 100vh;
    overflow: hidden;
  }
}
</style>

<script>
import trackingDefaultConfig from "@/projects/default/tracking";

import Header from "@/components/layout/Header/Header";
import Footer from "@/components/layout/Footer/Footer";

import cordovaMixin from "@/mixins/cordovaMixin";

import { setCustomVars } from "@johnpaul/jp-tracker";
import { ActivityIndicator } from "@johnpaul/jp-vue-components";

import { mapActions, mapGetters, mapState } from "vuex";

import { resolve } from "@/utilities/conductor";
import { getEnabledWidgets } from "@/utilities/config";
import { vibrate, hasEnabledLocation } from "@/utilities/cordova";

import { preventFrameBusting } from "@/utilities/frame-bust";

import Error from "@/components/Error";

import AppUpdate from "@/components/AppUpdate";
import RootedDevice from "@/components/RootedDevice";
import FrameBustedWindow from "@/components/FrameBustedWindow";
import router from "@/router";

import { mobilePlugin } from "@/plugins/plugins-mobile";

import {
  sfmcRegisterEvents,
  sfmcUnregisterEvents,
} from "@/modules/sfmc/pushNotifHandler";
import {
  ulRegisterEvents,
  ulUnregisterEvents,
} from "@/modules/universal-links/universalLinksHandler";

import {getConfig, getStore} from "./store";
import axios from "axios";

function versionCompare(v1, v2) {
  let vnum1 = 0,
    vnum2 = 0;

  for (let i = 0, j = 0; i < v1.length || j < v2.length; ) {
    while (i < v1.length && v1[i] != ".") {
      vnum1 = vnum1 * 10 + parseInt(v1[i]);
      i++;
    }

    while (j < v2.length && v2[j] != ".") {
      vnum2 = vnum2 * 10 + parseInt(v2[j]);
      j++;
    }

    if (vnum1 > vnum2) return 1;
    if (vnum2 > vnum1) return -1;

    vnum1 = vnum2 = 0;
    i++;
    j++;
  }
  return 0;
}

export default {
  name: "Application",

  mixins: [cordovaMixin],

  components: {
    ActivityIndicator,
    AppUpdate,
    Error,
    Footer,
    FrameBustedWindow,
    Header,
    RootedDevice,
  },

  data() {
    // fill widgets
    return {
      memberDataFetched: false,
      consentCookie: null,
      transparent: false,
      headerShadow: true,
      listeners: {},
      isOnline: true,
      newAppUrl: null,
      isRootedDevice: null,
      overflowStyle: "",
      componentKey: 0,
      isFingerprintOptionEnabled: getConfig()?.options.fingerprint,
      ATInternetID: null,
      isWindowFrameBusted: false,
    };
  },

  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
      subscription: "subscriptions/primarySubscription",
    }),

    ...mapState({
      member: state => state.member?.data,
      config: state => state.config,
      subscriptions: state => state.subscriptions?.data,
      salesforce: state => state.auth.salesforce,
      isMobileApp: state => state.isMobileApp,
    }),

    fetching() {
      return this.loggedIn && !this.memberDataFetched;
    },
    online() {
      return this.isOnline;
    },
    showNetworkError() {
      return window?.cordova && !this.isOnline;
    },
    enabledWidgets() {
      return getEnabledWidgets(this.config);
    },
  },

  methods: {
    ...mapActions({
      fetchMember: "member/fetch",
      fetchSubscriptions: "subscriptions/fetch",
      logout: "auth/logout",
      setIsMobileApp: "setIsMobileApp",
    }),
    async onLogout() {
      const configSuccessfullyRetrieved = await this.logout();
      if (this.isMobileApp) {
        mobilePlugin.onLogout();
        this.$root.$emit("logout.mobile:success");
      } else {
        this.$root.$emit("logout:success");
      }
      if (!configSuccessfullyRetrieved) {
        await this.$router.push({ name: "config-failure" });
      }
    },
    async fetchMemberData() {
      await Promise.all([this.fetchSubscriptions(), this.fetchMember()]);

      // Store tracking indicators
      setCustomVars({
        contactUUID: this.member?.id,
        subscriptionUUID: this.subscription?.id || "",
      });

      this.memberDataFetched = true;
    },
    updateHeader(params) {
      this.transparent = params.transparent || false;
      this.headerShadow = params.shadow;
    },
    getTrackerMethod(method) {
      const parts = method.split(".");
      const track = parts.reduce(
        (acc, p) => ({
          previous: acc.current || null,
          current: acc.current[p],
        }),
        { current: this.tracker },
      );

      // Returns function binded to its scope
      // (Tracking methods are exposed in classes)
      return track.current.bind(track.previous);
    },
    destroy() {
      if (this.ATInternetID) {
        trackingDefaultConfig.forEach(entry => {
          const { event } = entry;
          if (this.listeners[event]) {
            this.$root.$off(event, this.listeners[event]);
            delete this.listeners[event];
          }
        });
        this.listeners = {};
      }
    },
    async getNewVersion() {
      if (window.cordova) {
        const platform = process.env.PLATFORM.toLowerCase();
        const versionsUrl = this.config?.env.versionsUrl;
        const { data } = await axios.get(versionsUrl);
        const packageName = await window.cordova.getAppVersion.getPackageName();
        const packageVersion = await window.cordova.getAppVersion.getVersionNumber();

        const platformVersion = data?.[packageName]?.[platform].version;

        if (
          platformVersion &&
          versionCompare(packageVersion, platformVersion) < 0
        )
          this.newAppUrl = data[packageName][platform].url;
      }
    },
    setOverflow(overflow) {
      const body = document.querySelector("body");
      if (overflow !== "hidden") {
        // IOS fix
        body.style.removeProperty("position");
        body.style.removeProperty("overflow");
        body.style.removeProperty("height");
        body.style.removeProperty("top");
        body.style.removeProperty("width");
      } else {
        // IOS fix
        body.style.position = "fixed";
        body.style.overflow = "hidden";
        body.style.height = "100vh";
        body.style.top = "0";
        body.style.width = "100%";
      }
    },
  },

  watch: {
    async loggedIn(logged) {
      if (logged) {
        await this.fetchMemberData();
        mobilePlugin.onLogin(this.salesforce);
      }
    },
    online(isOnline) {
      // Prevent infinite fetching when app launched offline and logged in
      if (this.fetching) this.fetchMemberData();
      // Force jp-content re rendering after a reconnection
      if (isOnline) this.componentKey += 1;
    },
  },

  async created() {
    // Check internet connection
    try {
      document.addEventListener(
        "offline",
        () => {
          this.isOnline = false;
        },
        false,
      );
      document.addEventListener(
        "online",
        () => {
          this.isOnline = true;
        },
        false,
      );
      this.$root.$on("error.button.network:click", function() {
        if (window.cordova && window.cordova.plugins.settings) {
          window.cordova.plugins.settings.open("network");
        }
      });
    } catch (error) {
      /* Cordova not enabled */
    }
    // Set ATInternetID
    this.ATInternetID = getStore().state.config.env.ATInternetID;

    // Conductor configuration
    let conductorConfig = getStore().state.conductorConfig;
    conductorConfig.forEach(entry => {
      this.$root.$on(entry.event, function(params) {
        if (entry.type === "router")
          this.$router.push({
            name: entry.name,
            params: { ...entry?.params, ...params } || {},
            query: entry?.query || {},
          });
        else if (entry.type === "path") {
          this.$router.push({
            path: params.path || entry.path,
            query: params.query || entry.query || {},
          });
        } else if (entry.type === "cms") {
          const resolved = resolve(entry.event, params);
          this.$router.push(resolved.route);
        } else if (entry.type === "external") {
          document.location.href = entry.url;
          if (entry.event == "logout:success") document.location.reload();
        } else if (entry.type === "toggle-cookie") {
          if (window?.Optanon) window.Optanon.ToggleInfoDisplay();
        } else if (entry.type === "widget") {
          this.$root.$emit(entry.action, params);
        } else if (entry.type === "back") {
          if (window.history.length > 2) {
            router.go(-1);
          } else {
            this.$root.$emit("error.button.fallback:click");
          }
        } else if (entry.type === "vibrate") {
          vibrate(500);
        }
      });
    });
    // tracking
    if (this.ATInternetID) {
      trackingDefaultConfig.forEach(entry => {
        const { event, method, ...confParams } = entry;

        const fn = eventParams => {
          const trackerMethod = this.getTrackerMethod(method);
          trackerMethod({
            ...confParams,
            ...eventParams,
          });
        };
        this.$root.$on(event, fn);
        this.listeners[event] = fn;
      });
    }
  },

  mounted() {
    this.isWindowFrameBusted = preventFrameBusting(
      this.config?.options.frameBustAttempts,
    );

    this.setIsMobileApp(!!window.usingCordova);
    document.addEventListener(
      "deviceready",
      () => {
        // Check jailbreak/root detection
        if (
          window.cordova &&
          process.env.VUE_APP_ENVIRONMENT !== "development"
        ) {
          const rootPluginDetection = {
            Android: {
              plugin: "RootDetection",
              function: "isRooted",
            },
            iOS: {
              plugin: "JailbreakDetection",
              function: "isJailbroken",
            },
          };

          window.cordova.exec(
            isRooted => {
              this.isRootedDevice = isRooted;
            },
            () => {},
            rootPluginDetection[window.device.platform].plugin,
            rootPluginDetection[window.device.platform].function,
            [],
          );
        }

        // Device secure storage creation
        if (window.cordova && this.isFingerprintOptionEnabled) {
          this.initSecureStorage();
        }

        if (window.navigator && window.navigator.splashscreen) {
          setTimeout(function() {
            window.navigator.splashscreen.hide();
          }, 100);
        }
        this.getNewVersion();

        // Device Geolocation
        this.enableLocation();
        if (window.cordova.plugins.diagnostic) {
          window.cordova.plugins.diagnostic.isLocationEnabled(
            available => {
              available ? this.enableLocation() : this.disableLocation();
            },
            () => {
              this.disableLocation();
            },
          );
          window.cordova.plugins.diagnostic.registerLocationStateChangeHandler(
            state => {
              if (hasEnabledLocation(state)) {
                this.enableLocation();
              } else {
                this.disableLocation();
              }
            },
          );
        }
        mobilePlugin.onMobileReady();
      },
      false,
    );

    if (this.loggedIn) {
      this.fetchMemberData();
    }

    var link = document.createElement("link");
    link.href = require("assets/favicon.png");
    link.rel = "icon";
    document.head.appendChild(link);
    // intercept event for cm push notif
    sfmcRegisterEvents(this.$router);
    // intercept event for universal links
    ulRegisterEvents(this.$router);
  },
  unmounted() {
    window?.cordova?.plugins?.diagnostic?.registerLocationStateChangeHandler(
      false,
    );
    sfmcUnregisterEvents();
    ulUnregisterEvents();
  },
};
</script>
