<template>
  <div class="error-layer">
    <div v-html="icon"></div>
    <div class="title text1">
      {{ $t(`common.error.${type}.title`) }}
    </div>
    <div class="text text6">
      {{ $t(`common.error.${type}.text`) }}
    </div>
    <Button
      :id="`button--error`"
      class="button nav-item text6"
      @click.native.prevent="onButtonClick"
    >
      {{ $t(`common.error.${type}.button`) }}
    </Button>
    <div class="contact-us" v-if="hasMailtoLink || hasPhoneLink">
      <div class="text text6">
        {{ $t(`common.error.${type}.contact-us.text`) }}
      </div>
      <div class="links">
        <MailtoLink
          v-if="hasMailtoLink"
          id="link--mailto"
          :email="conciergeEmail"
          :text="conciergeEmail"
          class="link"
        />
        <PhoneLink
          v-if="hasPhoneLink"
          id="link--phone"
          :number="conciergePhone"
          class="link"
        >
          <span class="text5">{{ formattedNumber }}</span>
        </PhoneLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.error-layer {
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 rem(30px);
  height: 100%;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: rem(66px);

  svg {
    circle {
      @include setColor(--color-cta, fill);
    }
  }

  .title {
    margin-top: rem(46px);
    margin-bottom: rem(11px);
    @include setColor(--color-title1);
  }

  .text {
    @include setColor(--color-text);
  }

  .button {
    margin-top: rem(26px);
    max-width: rem(194px);
  }

  .contact-us {
    text-align: center;
    margin-top: rem(20px);

    .links {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: rem(30px);
      @include setColor(--color-link, color, true);

      .link {
        margin: rem(15px);
        a {
          color: inherit;
          span {
            vertical-align: middle;
          }
          text-decoration: none;
        }
      }

      @include respond-min("medium") {
        flex-direction: row;
        justify-content: center;

        .link {
          margin: 0 rem(15px);
        }
      }
    }
  }
}
</style>

<script>
import { Button, MailtoLink, PhoneLink } from "@johnpaul/jp-vue-components";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { mapState } from "vuex";
import { getConfig } from "@/store";

export default {
  components: {
    Button,
    MailtoLink,
    PhoneLink,
  },

  data() {
    const { conciergePhone, conciergeEmail } = getConfig().options;
    return {
      icon: "",
      conciergePhone,
      conciergeEmail,
    };
  },

  computed: {
    ...mapState({
      shouldFetchConfig: state => state.shouldFetchConfig,
      isMobileApp: state => state.isMobileApp,
    }),
    hasMailtoLink() {
      const emailKey = `common.error.${this.type}.contact-us.email`;
      const isEmailDefined = this.$t(emailKey) && this.$t(emailKey) != emailKey;
      return isEmailDefined && this.conciergeEmail;
    },
    hasPhoneLink() {
      const phoneKey = `common.error.${this.type}.contact-us.phone`;
      const isPhoneDefined = this.$t(phoneKey) && this.$t(phoneKey) != phoneKey;
      return isPhoneDefined && this.conciergePhone;
    },
    formattedNumber() {
      const phoneNumber = parsePhoneNumberFromString(this.conciergePhone);
      return phoneNumber?.formatInternational() || this.conciergePhone || "";
    },
    eventType() {
      return !this.shouldFetchConfig ? this.type : "fallback";
    },
  },

  props: {
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ["network", "404", "500"].includes(value);
      },
    },
  },

  methods: {
    onButtonClick() {
      this.$root.$emit(`error.button.${this.eventType}:click`);
      if (this.shouldFetchConfig) {
        window.location.reload();
      }
    },
  },

  mounted() {
    if (this.type) {
      import(
        /* webpackChunkName: "[request]" */ `!raw-loader!assets/images/errors/${this.type}.svg`
      ).then(icon => {
        this.icon = icon.default;
      });
    }
  },
};
</script>
