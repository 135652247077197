import { createLocalVue, mount } from "@vue/test-utils";
import { registerFormDefault } from "./dataset";
import { BootstrapVue } from "bootstrap-vue";
import Vuex from "vuex";
import defaultConfig from "@/projects/default/config.json";

import RegisterForm from "../views/RegisterForm";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);

function getDefaultStoreOptions() {
  return {
    state: {
      config: defaultConfig,
      i18n: {
        locale: "fr",
      },
      login: {},
      auth: {},
      register: {
        registering: false,
        registered: null,
        registerError: null,
        firstname: null,
        username: null,
      },
    },
    actions: {
      "register/register": () => {},
    },
    mutations: {
      "register/reset": () => {},
    },
  };
}

function getStore(options) {
  return new Vuex.Store({ ...getDefaultStoreOptions(), ...options });
}

export default () => {
  describe("Register Form", () => {
    let wrapper;

    describe("rendering", () => {
      describe("registering", () => {
        it("should display register form and should not display registered confirmation", () => {
          let storeOptions = getDefaultStoreOptions();

          wrapper = mount(RegisterForm, {
            store: getStore(storeOptions),
            data() {
              return {
                form: registerFormDefault,
              };
            },
            localVue,
          });
          const dynamicForm = wrapper.find(".dynamic-form");
          const registeredConfirmation = wrapper.find(".register-check");
          expect(dynamicForm.exists()).toBeTruthy();
          expect(registeredConfirmation.exists()).toBeFalsy();
        });

        it("should alert error if register failed", () => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state.register.registerError = "unknown";

          wrapper = mount(RegisterForm, {
            store: getStore(storeOptions),
            data() {
              return {
                form: registerFormDefault,
              };
            },
            localVue,
          });
          const alertError = wrapper.find("#alert--register-error");
          expect(alertError.exists()).toBeTruthy();
        });
      });

      describe("registered", () => {
        it("should display registered confirmation and should not display register form", () => {
          let storeOptions = getDefaultStoreOptions();
          storeOptions.state.register.registered = true;
          storeOptions.state.register.firstname = "firstname";
          storeOptions.state.register.username = "username@mail.com";

          wrapper = mount(RegisterForm, {
            store: getStore(storeOptions),
            data() {
              return {
                form: registerFormDefault,
              };
            },
            localVue,
          });
          const dynamicForm = wrapper.find(".dynamic-form");
          const registeredConfirmation = wrapper.find(".register-check");
          expect(dynamicForm.exists()).toBeFalsy();
          expect(registeredConfirmation.exists()).toBeTruthy();
        });
      });
    });
  });
};
