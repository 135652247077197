<template>
  <ColumnLayout class="password">
    <template slot="left" v-if="passwordIcon">
      <div class="text-right">
        <img src="~assets/images/password/icon.svg" alt="Password" />
      </div>
    </template>
    <template slot="right">
      <router-view />
    </template>
  </ColumnLayout>
</template>

<style lang="scss">
.password {
  .left {
    img {
      width: rem(180px);
    }
  }
  .right {
    form {
      margin-top: rem(35px);
    }
  }
}
</style>

<script>
import ColumnLayout from "@/components/layout/ColumnLayout";
import { getConfig } from "@/store";

export default {
  name: "Password",

  components: {
    ColumnLayout,
  },

  computed: {
    passwordIcon() {
      return getConfig()?.modules.password.options.icon;
    },
  },

  mounted() {
    this.$emit("header", { transparent: false });
  },
};
</script>
