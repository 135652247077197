<template>
  <div class="concierge-families">
    <div class="text8">
      {{ $t("request.concierge.families.subtitle") }}
    </div>
    <div class="title7">
      {{ $t("request.concierge.families.title") }}
    </div>

    <div class="mt-4 families clearfix" :style="{ height: containerHeight }">
      <div>
        <CategoryButton
          v-for="(subcategory, position) in subcategories"
          :key="subcategory.name"
          :category="subcategory"
          :position="position"
          :selected="subcategory === selectedSubcategory"
          :displayed="
            (selectedCategory && !selectedSubcategory) ||
              selectedSubcategory === subcategory
          "
          :topDelta="90"
          @mousedown.native="toggleSubcategory(subcategory)"
        />
      </div>
      <div>
        <template v-for="(category, position) in categories">
          <CategoryButton
            v-if="category.accessFromConcierge !== false"
            :key="category.name"
            :category="category"
            :position="position"
            :selected="category === selectedCategory"
            :displayed="
              (!selectedCategory || selectedCategory === category) &&
                !selectedSubcategory
            "
            @mousedown.native="toggleCategory(category)"
          />
        </template>
      </div>
    </div>

    <div v-if="showRecommendation" class="mt-5">
      <div class="text8">
        {{ $t("request.concierge.recommendation.subtitle") }}
      </div>
      <div class="title7">
        {{ $t(`request.concierge.recommendation.${categoryName}.title`) }}
      </div>
      <div class="submit-buttons">
        <Button
          id="button--concierge-recommendation-false"
          type="tertiary-negative"
          @click.native="selectRecommendation(false)"
        >
          <span class="text8">
            {{
              $t(
                `request.concierge.recommendation.button.${categoryName}.reservation`,
              )
            }}
          </span>
        </Button>
        <Button
          id="button--concierge-recommendation-true"
          type="tertiary-negative"
          @click.native="selectRecommendation(true)"
        >
          <span class="text8">
            {{ $t("request.concierge.recommendation.button.recommendation") }}
          </span>
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.concierge-families {
  padding: rem(16px 12px);

  .title1,
  .text2 {
    @include setColor(--color-text);
  }

  .families {
    min-height: 90px;
    position: relative;
    overflow: visible;
    z-index: 2;
  }

  .submit-buttons {
    margin-top: 31px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .button {
      width: 48%;
      margin: 0;
      @include setColor(--color-text);

      &:hover {
        opacity: 1;
        @include setColor(--color-text-negative);
        @include setColor(--color-highlight, background-color);
      }
    }
  }

  .button {
    width: auto;
    float: left;
    margin-right: rem(16px);
  }
}
</style>

<script>
import { mapMutations } from "vuex";

import { Button } from "@johnpaul/jp-vue-components";
import CategoryButton from "./CategoryButton";

import { getModuleConfig } from "@/utilities/config";
import { parseConciergeCategories } from "@/utilities/categories";
import { getConfig } from "@/store";

export default {
  components: { Button, CategoryButton },

  data() {
    const config = getConfig();
    const moduleConfig = getModuleConfig(config, "request/concierge");
    return {
      categories: parseConciergeCategories(moduleConfig.options.categories),
      selectedCategory: null,
      selectedSubcategory: null,
      subcategories: [],
    };
  },

  computed: {
    categoryName() {
      const category = this.selectedSubcategory || this.selectedCategory;
      return category?.name;
    },

    containerHeight() {
      if (this.selectedSubcategory) return "90px";

      const nbItemsDisplayed = this.selectedCategory
        ? this.selectedCategory.categories.length + 2
        : this.categories.filter(c => c.accessFromConcierge !== false).length;

      return `${90 * Math.ceil(nbItemsDisplayed / 2)}px`;
    },

    showRecommendation() {
      const category = this.selectedSubcategory || this.selectedCategory;
      return !!category?.recommendation;
    },
  },

  watch: {
    showRecommendation() {
      this.$root.$emit(
        this.showRecommendation
          ? "mars.recommendation:mounted"
          : "mars.category:mounted",
        {
          category: this.selectedCategory?.name,
          subcategory: this.selectedSubcategory?.name,
        },
      );
    },
  },

  methods: {
    ...mapMutations({
      resetRequest: "request/resetRequest",
      setCategory: "request/setCategory",
      setRecommendation: "request/setRecommendation",
    }),

    emitCategoryClickEvent() {
      this.$root.$emit("mars.category:click", {
        category: this.selectedCategory?.name,
        subcategory: this.selectedSubcategory?.name,
      });
    },

    toggleCategory(category) {
      if (this.selectedCategory) {
        this.selectedCategory = null;
        return;
      }

      this.selectedCategory = category;
      this.subcategories = category.categories;
      if (!category.categories.length) {
        this.emitCategoryClickEvent();
      }

      if (!category.categories.length && !category.recommendation) {
        this.setCategory(category);
        this.$emit("nav-next");
      }
    },

    toggleSubcategory(subcategory) {
      if (this.selectedSubcategory) {
        this.selectedSubcategory = null;
        return;
      }

      this.selectedSubcategory = subcategory;
      this.emitCategoryClickEvent();

      if (!subcategory.recommendation) {
        this.setCategory(subcategory);
        this.$emit("nav-next");
      }
    },

    selectRecommendation(recommendation) {
      const category = this.selectedSubcategory || this.selectedCategory;
      const reco = recommendation ? "recommendation" : "reservation";

      this.$root.$emit("mars.recommendation:click", {
        category: category.name,
        recommendation: reco,
      });

      this.setCategory(category);
      this.setRecommendation(recommendation);
      this.$emit("nav-next");
    },
  },

  mounted() {
    this.resetRequest();
    this.$root.$emit("mars.category:mounted");
  },
};
</script>
