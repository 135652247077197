<template>
  <b-container class="cityguides">
    <b-row>
      <b-col>
        <h1 class="text1">{{ textComponent.name }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div
          v-for="(row, rowIdx) in templatizedCities"
          :key="`row-${rowIdx}`"
          class="city-row level1"
        >
          <!-- row -->
          <template v-if="Array.isArray(row)">
            <div
              v-for="(col, colIdx) in row"
              :key="`col-${colIdx}`"
              class="city-col level2"
              :style="{ 'min-width': `${100 / row.length}%` }"
            >
              <!-- col -->
              <template v-if="Array.isArray(col)">
                <div
                  v-for="(row2, row2Idx) in col"
                  :key="`row2-${row2Idx}`"
                  class="city-row level3"
                >
                  <template v-if="Array.isArray(row2)">
                    <div
                      v-for="(col2, col2Idx) in row2"
                      :key="`col2-${col2Idx}`"
                      class="city-col city-cell level4"
                      :style="{ 'min-width': `${100 / row2.length}%` }"
                    >
                      <CityCard
                        :city="col2"
                        @display="trackDisplay(col2)"
                        @mousedown.native="trackClick(col2)"
                      />
                    </div>
                  </template>
                  <div v-else class="city-cell">
                    <CityCard
                      :city="row2"
                      @display="trackDisplay(row2)"
                      @mousedown.native="trackClick(row2)"
                    />
                  </div>
                </div>
              </template>
              <div v-else class="city-cell">
                <CityCard
                  :city="col"
                  @display="trackDisplay(col)"
                  @mousedown.native="trackClick(col)"
                />
              </div>
            </div>
          </template>
          <div v-else class="city-cell">
            <CityCard
              :city="row"
              @display="trackDisplay(row)"
              @mousedown.native="trackClick(row)"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <ActivityIndicator v-if="fetching" />
  </b-container>
</template>

<style lang="scss">
.cityguides {
  margin: rem(0 auto);
  margin-top: 0 !important;
  padding-top: 0 !important;

  @include respond-min("medium") {
    margin: rem(0 auto 64px);
    max-width: 85vw;
  }

  @include respond-min("xlarge") {
    margin: rem(0 auto 100px);
  }

  h1 {
    margin: rem(21px 0);
    text-align: center;
    @include setColor(--color-title1);

    @include respond-min("medium") {
      margin: rem(46px 0);
    }
  }

  .img {
    position: relative;
    height: rem(180px);
    background-size: cover;
    background-position: center center;
    text-align: center;
    @include setColor(--color-text-negative);
    text-transform: uppercase;
    margin-bottom: rem(15px);

    &:hover {
      text-decoration: none;
    }

    p {
      margin: 0;
      z-index: 1;
    }

    .city-name {
      margin-bottom: rem(8px);
    }

    @include respond-min("medium") {
      height: rem(307px);
      margin-bottom: rem(30px);
    }
  }

  .city-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
  }

  .city-row {
    display: flex;
    flex-grow: 1;
  }

  .city-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .city-cell {
    flex-grow: 1;
    height: rem(323px);
    padding: rem(8px);
  }

  @include respond-to("medium") {
    .city-row {
      flex-direction: column;
    }

    .city-cell {
      height: rem(196px);
      padding: rem(8px 0);
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { ActivityIndicator } from "@johnpaul/jp-vue-components";
import CityCard from "../components/CityCard.vue";
import contentPageMixin from "@/mixins/contentPageMixin";
import { applyTemplate } from "./cities-template";
import { getComponentVendorId } from "@/utilities/tracking";

const template = [
  ["item", ["item", "item"]],
  [["item", "item"], "item"],
  [[["item", "item"]], "item"],
  ["item", [["item", "item"]]],
  [[["item", "item", "item", "item"]]],
];

export default {
  components: {
    ActivityIndicator,
    CityCard,
  },

  mixins: [contentPageMixin],

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
      fetching: state => state.content.fetchingComponents,
    }),

    components() {
      return this.getComponents(this.pageCollections?.[0]);
    },

    templatizedCities() {
      if (!this.mediaComponents) return [];
      return applyTemplate(template, this.mediaComponents);
    },

    mediaComponents() {
      const mediaTypes = ["media", "static"];
      return this.components.filter(component =>
        mediaTypes.includes(component.type),
      );
    },

    textComponent() {
      return this.components.find(component => component.type === "text") || {};
    },
  },

  methods: {
    getPublisherParams(component) {
      const index = this.mediaComponents.indexOf(component);

      return {
        city: getComponentVendorId(component),
        positionItem: index + 1,
        nbItems: this.mediaComponents.length,
        memberId: this.memberId,
        url: encodeURIComponent(window.location.toString()),
      };
    },

    trackDisplay(component) {
      this.tracker.publishers.contents.city(this.getPublisherParams(component));
    },
    trackClick(component) {
      this.tracker.publishers.contents.clickCity(
        this.getPublisherParams(component),
      );
    },
  },

  mounted() {
    this.$root.$emit("city-guides.list:mounted");
    this.$emit("header", { transparent: false });
  },
};
</script>
