import { shallowMount, createLocalVue, createWrapper } from "@vue/test-utils";
import VueRouter from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import Vuex from "vuex";
import defaultConfig from "@/projects/default/config.json";

import Login from "../Login";

jest.mock("@/utilities/cordova", () => ({
  getFromSecureStorage: jest.fn(() => Promise.resolve()),
}));

import { getFromSecureStorage } from "@/utilities/cordova";

const localVue = createLocalVue();
localVue.use(Vuex);
localVue.use(BootstrapVue);
localVue.use(VueRouter);

export default () => {
  let wrapper;
  const state = {
    config: defaultConfig,
    login: {
      error: null,
      fetching: null,
      redirect: null,
    },
    auth: {
      verifyingAccount: null,
      verifySuccess: false,
      verifyError: false,
      fingerprint: null,
      fillingFingerprintAuthorization: false,
    },
    route: {
      query: {
        vtoken: null,
      },
    },
  };
  const actions = {
    "login/fetchToken": jest.fn().mockReturnValue({ success: true }),
    "login/refreshToken": jest.fn(),
    "auth/verifyAccount": jest.fn(),
  };
  const mutations = {
    "auth/disableFingerprint": jest.fn().mockName("auth/disableFingerprint"),
    "auth/setTokens": () => {},
    "auth/resetFingerprint": jest.fn().mockName("auth/resetFingerprint"),
    "auth/startFillingFingerprintAuthorization": jest
      .fn()
      .mockName("auth/startFillingFingerprintAuthorization"),
    "auth/stopFillingFingerprintAuthorization": jest
      .fn()
      .mockName("auth/stopFillingFingerprintAuthorization"),
  };
  const data = {
    username: "test@test.com",
    password: "Test1234!",
    isFingerprintOptionEnabled: false,
    authType: "none",
    showFingerprintModal: false,
  };
  const cordovaMixinBiometricFullSuccess = {
    methods: {
      getAuthenticationType: jest.fn().mockReturnValue("biometric"),
      authorizeFingerprint: jest.fn().mockReturnValue(true),
    },
  };
  const cordovaMixinWithoutAuthType = {
    methods: {
      getAuthenticationType: jest.fn().mockReturnValue(null),
    },
  };

  const originalWindow = { ...window };
  const windowSpy = jest.spyOn(global, "window", "get");
  windowSpy.mockImplementation(() => ({
    ...originalWindow,
    cordova: true,
  }));
  const router = new VueRouter();

  let createNewWrapper = ({
    customState,
    customActions = () => {},
    customMutations = () => {},
    customData,
    customMixins,
    customStubs,
  }) => {
    const store = new Vuex.Store({
      state: {
        ...state,
        ...customState,
      },
      actions: {
        ...actions,
        ...customActions,
      },
      mutations: {
        ...mutations,
        ...customMutations,
      },
    });
    return shallowMount(Login, {
      store,
      localVue,
      mixins: customMixins,
      data() {
        return {
          ...data,
          ...customData,
        };
      },
      stubs: customStubs,
      router,
    });
  };

  it("should mount", () => {
    wrapper = createNewWrapper({});
    expect(wrapper.vm).toBeTruthy();
  });

  describe("Clicks", () => {
    it("I click on forgot password link", async () => {
      wrapper = createNewWrapper({});
      const link = wrapper.find("#link--login-recover-password");
      const rootWrapper = createWrapper(wrapper.vm.$root);
      link.trigger("click");
      expect(rootWrapper.emitted("login.links.password:click")).toBeTruthy();
    });
  });

  describe("Fingerprint", () => {
    const customState = {
      auth: {
        fillingFingerprintAuthorization: true,
      },
    };
    const customData = {
      isFingerprintOptionEnabled: true,
    };

    it("should request fingerprint authorization and display fingerprint modal", async () => {
      wrapper = createNewWrapper({
        customState,
        customData: {
          ...customData,
          authType: "biometric",
        },
        customMixins: [cordovaMixinBiometricFullSuccess],
        customStubs: ["FingerPrintModal"],
      });
      expect(wrapper.vm.$data.showFingerprintModal).toBeFalsy();
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeFalsy();
      await wrapper.vm.requestFingerprintAuthorization();
      expect(wrapper.vm.$data.showFingerprintModal).toBeTruthy();
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeTruthy();
    });

    it("should request fingerprint authorization and not display fingerprint modal", async () => {
      wrapper = createNewWrapper({
        customState,
        customData,
        customMixins: [cordovaMixinWithoutAuthType],
        customStubs: ["FingerPrintModal"],
      });
      expect(wrapper.vm.$data.showFingerprintModal).toBeFalsy();
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeFalsy();
      await wrapper.vm.requestFingerprintAuthorization();
      expect(wrapper.vm.$data.showFingerprintModal).toBeFalsy();
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeFalsy();
    });

    it("should hide modal and emit login succeed event after authorizing fingerprint", async () => {
      wrapper = createNewWrapper({
        customData: {
          ...customData,
          showFingerprintModal: true,
        },
        customState,
        customMixins: [cordovaMixinBiometricFullSuccess],
        customStubs: ["FingerPrintModal"],
      });
      const rootWrapper = createWrapper(wrapper.vm.$root);
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeTruthy();
      await wrapper.vm.authorize();
      expect(
        mutations["auth/stopFillingFingerprintAuthorization"],
      ).toHaveBeenCalled();
      expect(rootWrapper.emitted("login:success")).toBeTruthy();
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeFalsy();
    });

    it("should hide modal, emit login succeed event and disable fingerprint after rejecting fingerprint", async () => {
      wrapper = createNewWrapper({
        customData: {
          ...customData,
          showFingerprintModal: true,
        },
        customState,
        customMixins: [cordovaMixinBiometricFullSuccess],
        customStubs: ["FingerPrintModal"],
      });
      const rootWrapper = createWrapper(wrapper.vm.$root);
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeTruthy();
      await wrapper.vm.refuse();
      expect(
        mutations["auth/stopFillingFingerprintAuthorization"],
      ).toHaveBeenCalled();
      expect(mutations["auth/disableFingerprint"]).toHaveBeenCalled();
      expect(rootWrapper.emitted("login:success")).toBeTruthy();
      expect(wrapper.find("fingerprintmodal-stub").exists()).toBeFalsy();
    });

    it("should request fingerprint authorization", async () => {
      wrapper = createNewWrapper({
        customState: {
          ...customState,
          auth: {
            fingerprint: null,
          },
        },
        customData,
        customMixins: [cordovaMixinBiometricFullSuccess],
        customStubs: ["FingerPrintModal"],
      });
      const spyRequestFingerprintAuthorization = jest.spyOn(
        wrapper.vm,
        "requestFingerprintAuthorization",
      );
      await wrapper.vm.onSubmit();
      expect(
        mutations["auth/startFillingFingerprintAuthorization"],
      ).toHaveBeenCalled();
      expect(actions["login/fetchToken"]).toHaveBeenCalled();
      expect(spyRequestFingerprintAuthorization).toHaveBeenCalled();
    });

    describe("should not request fingerprint authorization and emit login succeed event", () => {
      it("when fingerprint option is not enabled", async () => {
        wrapper = createNewWrapper({
          customState,
          customData: {
            isFingerprintOptionEnabled: false,
          },
          customMixins: [cordovaMixinBiometricFullSuccess],
        });
        const spyRequestFingerprintAuthorization = jest.spyOn(
          wrapper.vm,
          "requestFingerprintAuthorization",
        );
        const rootWrapper = createWrapper(wrapper.vm.$root);
        await wrapper.vm.onSubmit();
        expect(
          mutations["auth/startFillingFingerprintAuthorization"],
        ).toHaveBeenCalled();
        expect(actions["login/fetchToken"]).toHaveBeenCalled();
        expect(rootWrapper.emitted("login:success")).toBeTruthy();
        expect(spyRequestFingerprintAuthorization).not.toHaveBeenCalled();
        expect(
          mutations["auth/stopFillingFingerprintAuthorization"],
        ).toHaveBeenCalled();
      });

      it("when fingerprint choice is already set", async () => {
        wrapper = createNewWrapper({
          customState: {
            auth: {
              fillingFingerprintAuthorization: true,
              fingerprint: true,
            },
          },
          customData,
          customMixins: [cordovaMixinBiometricFullSuccess],
        });
        const spyRequestFingerprintAuthorization = jest.spyOn(
          wrapper.vm,
          "requestFingerprintAuthorization",
        );
        const rootWrapper = createWrapper(wrapper.vm.$root);
        await wrapper.vm.onSubmit();
        expect(
          mutations["auth/startFillingFingerprintAuthorization"],
        ).toHaveBeenCalled();
        expect(actions["login/fetchToken"]).toHaveBeenCalled();
        expect(rootWrapper.emitted("login:success")).toBeTruthy();
        expect(spyRequestFingerprintAuthorization).not.toHaveBeenCalled();
        expect(
          mutations["auth/stopFillingFingerprintAuthorization"],
        ).toHaveBeenCalled();
      });
    });

    it("should get data from secure storage and fetch token", async () => {
      wrapper = createNewWrapper({
        customState: {
          ...customState,
          auth: {
            fingerprint: true,
          },
        },
        customData,
        customMixins: [cordovaMixinBiometricFullSuccess],
        customStubs: ["FingerPrintModal"],
      });
      await wrapper.vm.loginWithFingerPrint();
      expect(getFromSecureStorage).toHaveBeenCalled();
      expect(actions["login/fetchToken"]).toHaveBeenCalled();
    });
  });
};
