<template>
  <div class="consent">
    <ActivityIndicator v-if="fetching" />
    <div class="consent__content" v-else>
      <div class="title7 mb-2">
        {{ $t("common.consent.title1", { partner: clientName }) }}
      </div>
      <div class="text4">
        {{ $t("common.consent.subtext1", { partner: clientName }) }}
      </div>
      <div class="title7 mb-2 mt-4">{{ $t("common.consent.title2") }}</div>
      <div class="text4">
        <div class="mb-2">{{ $t("common.consent.subtext2") }}</div>
        <ul>
          <li v-for="info in ssoInfosLocalizedKeys" :key="info">
            {{ $t(info) }}
          </li>
        </ul>
      </div>
      <div class="consent__privacy-policy mb-2">
        <CheckboxInput
          :class="['consent__checkbox', error && 'consent__checkbox--error']"
          id="checkbox-privacy-policy"
          :value="privacyPolicyCheckbox"
          @update:value="onChangeCheckbox($event)"
        />
        <span>
          {{ $t("common.consent.privacy-policy.pre", { partner: clientName }) }}
          <a
            class="secondary-link link consent__link"
            :href="privacyPolicyUrl"
            target="_blank"
          >
            {{ $t("common.consent.privacy-policy.link") }}
          </a>
          {{
            $t("common.consent.privacy-policy.post", { partner: clientName })
          }}
        </span>
      </div>
      <div class="text4 mt-4 mb-4">
        {{ $t("common.consent.subtext3", { partner: clientName }) }}
      </div>
      <div class="consent__buttons">
        <Button
          class="consent__button button-secondary"
          id="deny"
          @click.native="sendConsent(false)"
        >
          {{ $t("common.consent.deny") }}
        </Button>
        <Button
          class="consent__button"
          id="accept"
          @click.native="sendConsent(true)"
        >
          {{ $t("common.consent.accept") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.consent {
  display: flex;
  justify-content: center;
  padding: 24px;

  &__content {
    margin-top: 24px;
    max-width: 460px;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
  }
  &__button {
    margin: 24px 12px 0 12px;
  }
  &__privacy-policy {
    display: flex;
    flex-direction: row;
  }
  &__checkbox {
    &--error {
      &.checkbox-input .indicator {
        border-color: var(--color-status-error);
      }
    }

    width: 21px;
    margin: 6px 8px 0 8px;
  }
  &__link {
    font-weight: 900;
    color: inherit;
    text-decoration: underline;
  }
}
</style>

<script>
import {
  Button,
  ActivityIndicator,
  CheckboxInput,
} from "@johnpaul/jp-vue-components";
import client from "@/plugins/jp-api-client";
import { mapState } from "vuex";

export default {
  components: {
    Button,
    ActivityIndicator,
    CheckboxInput,
  },
  data() {
    return {
      fetching: true,
      privacyPolicyCheckbox: false,
      error: false,
      payload: {},
      informationList: [],
      clientName: "",
      privacyPolicyUrl: "",
    };
  },
  computed: {
    ...mapState({
      isMobileApp: state => state.isMobileApp,
    }),
    ssoInfosLocalizedKeys() {
      return this.informationList.map(
        value => `common.consent.infos.${value}.label`,
      );
    },
  },
  async mounted() {
    this.$root.$emit("authorize.page:mounted");
    const response = await client.auth.v2.getSSOInfos(
      this.$route.query.client_id,
    );
    this.informationList = response.data.member_available_fields;
    this.clientName = response.data.display_name;
    this.privacyPolicyUrl = response.data.privacy_policy_url;
    this.payload = this.$route.query;
    this.fetching = false;
    this.$emit("header", {
      transparent: false,
    });
  },
  methods: {
    onChangeCheckbox(value) {
      this.privacyPolicyCheckbox = value;
    },
    async sendConsent(accepted) {
      if (accepted) {
        if (!this.privacyPolicyCheckbox) {
          this.error = true;
          return;
        }
        try {
          this.$root.$emit("authorize.accept-consent:click");
          this.fetching = true;
          const response = await client.auth.v2.authorize(this.payload);
          window.open(
            response.data.redirect_uri,
            this.isMobileApp ? "_blank" : "_self",
          );
        } catch (e) {
          let error_status = "unsupported_response_type";
          switch (e.response.status) {
            case 400:
            case 401:
              error_status = "invalid_request";
              break;
            case 500:
              error_status = "server_error";
              break;
            case 503:
              error_status = "temporary_unavalaible";
              break;
            default:
              break;
          }
          window.open(
            `${this.$route.query?.redirect_uri}?error=${error_status}${
              this.$route.query?.state
                ? `&state=${this.$route.query?.state}`
                : ""
            }`,
            this.isMobileApp ? "_blank" : "_self",
          );
        }
      } else {
        this.$root.$emit("authorize.denied-consent:click");
        window.open(
          `${this.$route.query?.redirect_uri}?error=access_denied${
            this.$route.query?.state ? `&state=${this.$route.query?.state}` : ""
          } `,
          this.isMobileApp ? "_blank" : "_self",
        );
      }
      this.fetching = true;
    },
  },
};
</script>
