<template>
  <ActivityIndicator v-if="fetching" />
  <div class="my-account--informations" v-else>
    <Alert variant="success" v-if="updated">
      <span v-html="$t(`my-account.informations.success`)"></span>
    </Alert>
    <Alert variant="error" v-if="error">
      <span v-html="$t(`my-account.informations.error.${error}`)"></span>
    </Alert>
    <form @submit.prevent="onSubmit" novalidate>
      <div class="informations" v-if="forms.details">
        <h2 class="text5">
          {{ $t("my-account.informations.personal-details.title") }}
        </h2>
        <SelectInput
          v-if="forms.details.salutation"
          id="input--my-account-salutation"
          :value.sync="salutation"
          :options="salutations"
          :label="$t('my-account.form.inputs.salutation')"
        />
        <TextInput
          v-if="forms.details.firstname"
          id="input--my-account-firstname"
          :value.sync="firstname"
          :label="$t('my-account.form.inputs.firstname')"
          :disabled="submitting"
        />
        <TextInput
          id="input--my-account-lastname"
          v-if="forms.details.lastname"
          :value.sync="lastname"
          :label="$t('my-account.form.inputs.lastname')"
          :disabled="submitting"
        />
      </div>
      <div class="phones" v-if="forms.phones && forms.phones.length">
        <h2 class="text5">
          {{ $t("my-account.informations.phones.title") }}
        </h2>
        <template v-for="(field, index) in phones">
          <div class="d-flex text6" :key="`phone-header-${field.type}`">
            <div class="flex-fill">
              {{ $t(`my-account.form.inputs.phone.${field.type}.label`) }}
            </div>
            <a
              v-if="!field.required"
              :id="`link-delete-phone-${field.type}`"
              class="text-right flex-fill"
              @click="deleteField('phones', field.type)"
              >{{ $t("my-account.links.phone.delete") }}</a
            >
          </div>
          <TelInput
            :style="{ 'z-index': forms.phones.length - index }"
            :key="field.type"
            :id="`input--my-account-${field.type}`"
            :label="$t(`my-account.form.inputs.phone.${field.type}.number`)"
            :areaLabel="$t(`my-account.form.inputs.phone.${field.type}.area`)"
            :value="phones[index].value"
            :required="field.required || false"
            :defaultCountryCode="defaultCountryCode"
            :disabled="submitting"
            @update:value="updatePhone(field, $event)"
            :error="errors.phones[field.type]"
            @focus="errors.phones[field.type] = null"
            @blur="checkPhone(field.type)"
          />
        </template>
        <template v-for="(type, index) in getAvailableTypes('phones')">
          <Link
            class="add-phone-link text7"
            fontIcon="ui_add"
            :text="$t(`my-account.informations.phone.links.add.${type}`)"
            :key="`link-add-phone-${index}`"
            @click="addPhone(type)"
          />
        </template>
      </div>
      <div class="addresses" v-if="forms.addresses && forms.addresses.length">
        <h2 class="text5">
          {{ $t("my-account.informations.addresses.title") }}
        </h2>
        <div v-if="addresses && addresses.length" class="addresses-content">
          <template v-for="(field, index) in addresses">
            <div
              v-if="addresses.length > 1"
              class="d-flex text6"
              :key="`address-header-${field.type}`"
            >
              <div class="flex-fill">
                {{ $t(`my-account.addresses.label.${field.type}`) }}
              </div>
            </div>
            <div :key="`address-input-${field.type}`">
              <TextInput
                :id="`input--my-account-addresses-${field.type}-street`"
                :class="[
                  'address-input',
                  field.disabled && 'address-input--disabled',
                ]"
                :value.sync="addresses[index].value.street"
                :label="$t('my-account.form.inputs.addresses.street.label')"
                :disabled="field.disabled || submitting"
                :text-tooltip="
                  field.disabled
                    ? $t('my-account.form.inputs.addresses.street.tooltip')
                    : null
                "
              />
              <div class="d-flex ">
                <TextInput
                  :id="`input--my-account-addresses-${field.type}-city`"
                  :value.sync="addresses[index].value.city"
                  :label="$t('my-account.form.inputs.addresses.city')"
                  :class="[
                    'address-input',
                    'flex-fill',
                    'mr-2',
                    field.disabled && 'address-input--disabled',
                  ]"
                  :disabled="field.disabled || submitting"
                />
                <TextInput
                  :id="`input--my-account-addresses-${field.type}-zip-code`"
                  :value.sync="addresses[index].value.zip_code"
                  :label="$t('my-account.form.inputs.addresses.zip-code')"
                  :class="[
                    'address-input',
                    'flex-fill',
                    'mr-2',
                    field.disabled && 'address-input--disabled',
                  ]"
                  :disabled="field.disabled || submitting"
                />
              </div>
              <SelectInput
                :id="`input--my-account-addresses-${field.type}-country-code`"
                :class="[
                  'address-input',
                  field.disabled && 'address-input--disabled',
                ]"
                :value.sync="addresses[index].value.country_code"
                :options="countries"
                :label="$t('my-account.form.inputs.addresses.country')"
                :disabled="field.disabled || submitting"
              />
            </div>
          </template>
        </div>
        <div v-else class="addresses-empty text6">
          {{ $t("my-account.informations.addresses.complete.pre") }}
          <a class="complete-addresses-link text5">
            {{ $t("my-account.informations.addresses.complete.link") }}
          </a>
        </div>
      </div>

      <div class="personal" v-if="forms.personal">
        <h2 class="text5">
          {{ $t("my-account.informations.personal.title") }}
        </h2>
        <SelectInput
          v-if="forms.personal['marital-situation']"
          :id="`input--my-account-personal-marital-status`"
          :value.sync="maritalStatus"
          :options="
            forms.personal['marital-situation'].map(o => ({
              value: o,
              text: $t(`common.family-situation.${o}`),
            }))
          "
          :label="$t('my-account.informations.personal.family-situation')"
          :disabled="submitting"
        />
      </div>

      <div class="organization" v-if="showOrganization">
        <h2 class="text5">
          {{ $t("my-account.informations.organization.title") }}
        </h2>
        <TextInput
          v-if="forms.organization.name"
          id="input--my-account-organization-name"
          :value.sync="organizationName"
          :label="$t('my-account.form.inputs.organization-name')"
          :disabled="submitting"
        />
        <SelectInput
          v-if="forms.organization.industry"
          id="input--my-account-organization-industry"
          :value.sync="organizationIndustry"
          :options="industryItems"
          :disabled="submitting"
          :label="$t('my-account.form.inputs.organization-industry')"
        />
        <SelectInput
          v-if="forms.organization.size"
          id="input--my-account-size"
          :value.sync="organizationSize"
          :options="organizationSizes"
          :disabled="submitting"
          :label="$t('my-account.form.inputs.organization-size')"
        />
      </div>
      <ActivityIndicator v-if="submitting" />
      <Button
        class="mb-3"
        nativeType="submit"
        id="save"
        v-else
        :disabled="!canBeSubmitted"
      >
        {{ $t("my-account.informations.form.buttons.submit") }}
      </Button>
    </form>
  </div>
</template>

<style lang="scss">
.my-account--informations {
  @include setColor(--color-text);

  .title3 {
    margin-bottom: rem(10px);
  }
  .title4 {
    margin-bottom: rem(14px);
  }
  .text6 {
    @include setColor(--color-text);
    margin-bottom: rem(12px);
  }
  .informations,
  .phones,
  .addresses {
    padding-bottom: rem(31px);
  }

  .add-phone-link,
  .add-phone-link a {
    @include setColor(--color-link);
  }

  button {
    @include setColor(--color-text-negative, color, true);
  }

  .address-input {
    opacity: 1 !important;

    & select,
    & input {
      opacity: 1 !important;
      padding: rem(7px 15px);
    }

    &--disabled {
      & select,
      & input {
        font-weight: bold;
        background-color: #f8f9fa !important;
        background-image: none !important;
        @include setColor(--color-inactive, color, true);
      }
    }
  }

  .complete-addresses-link {
    @include setColor(--color-link, color);
  }
}
</style>

<script>
import {
  ActivityIndicator,
  Alert,
  Button,
  Link,
  SelectInput,
  TelInput,
  TextInput,
} from "@johnpaul/jp-vue-components";
import { mapActions, mapMutations, mapState } from "vuex";

import { getModuleConfig } from "@/utilities/config";
import { getBrowserCountryCode } from "@/utilities/i18n";

import transforms from "@/utilities/transforms";

import validators from "@/utilities/validators";

import { industryItems, organizationSizes } from "@/constants/organization";
import { getConfig } from "@/store";

export default {
  name: "MyAccountInformations",

  components: {
    ActivityIndicator,
    Alert,
    Button,
    Link,
    SelectInput,
    TelInput,
    TextInput,
  },

  data() {
    const config = getConfig();
    return {
      forms: getModuleConfig(config, "my-account/informations").options
        .forms,
      maritalStatus: null,
      salutation: null,
      firstname: null,
      lastname: null,
      phones: [],
      addresses: [],
      organizationIndustry: null,
      organizationName: null,
      organizationSize: null,
      errors: {
        phones: {},
      },
      salutations: transforms.translateStringOptions({
        options: config.options.salutations,
      }).options,
      countries: config.options.countries
        .map(country => ({
          value: country,
          text: this.$t(`common.countries.${country}`),
        }))
        .sort(function(a, b) {
          return a.text.localeCompare(b.text);
        }),
      industryItems: transforms.translateStringOptions({
        options: industryItems.map(item => ({
          value: item,
          text: `common.industry.${item}`,
        })),
      }).options,
      organizationSizes,
    };
  },

  computed: {
    ...mapState({
      locale: state => state.i18n.locale,
      member: state => state.member.data,
      fetching: state => state.member.fetching,
      submitting: state => state["my-account"].updating,
      updated: state => state["my-account"].updated,
      error: state => state["my-account"].updateError,
    }),

    showOrganization() {
      return ["name", "industry", "size"].some(
        attr => this.forms.organization?.[attr],
      );
    },

    canBeSubmitted() {
      return this.checkDetails && this.checkPhones && this.checkAddresses;
    },

    checkDetails() {
      const { details = {} } = this.forms;
      return (
        (!details.lastname || this.lastname) &&
        (!details.firstname || this.firstname)
      );
    },

    checkPhones() {
      return (
        this.phones.filter(
          phone => !phone.value || !validators.isPhoneNumber(phone.value),
        ).length === 0
      );
    },

    checkAddresses() {
      return (
        this.addresses.filter(
          address =>
            !address.value.street ||
            !address.value.city ||
            !address.value.zip_code ||
            !address.value.country_code,
        ).length === 0
      );
    },

    defaultCountryCode() {
      return getBrowserCountryCode();
    },
  },

  methods: {
    ...mapActions({
      fetchMember: "member/fetch",
      update: "my-account/update",
    }),
    ...mapMutations({
      reset: "my-account/reset",
    }),
    getTypes(group) {
      return this.forms[group].map(entry => entry.type);
    },
    getAvailableTypes(group) {
      const settedTypes = this[group].map(entry => entry.type);
      return this.getTypes(group).filter(x => !settedTypes.includes(x));
    },
    checkPhone(type) {
      const phoneToCheck = this.phones.filter(phone => phone.type === type)[0];
      let error = null;
      if (!phoneToCheck.value) {
        error = this.$t("common.form.input.error.missing");
      } else if (!validators.isPhoneNumber(phoneToCheck.value)) {
        error = this.$t("common.form.input.validators.isPhoneNumber.error");
      }
      this.$set(this.errors.phones, type, error);
    },
    addField(group, type, defaultValue, required) {
      this[group].push({
        type,
        value: defaultValue,
        required,
      });
      this.sortByRequired(this[group]);
    },
    addPhone(type) {
      this.addField("phones", type, "", false);
    },
    addAddress(type) {
      const required =
        this.forms.addresses.filter(address => address.type === type)[0]
          .required || false;
      this.addField(
        "addresses",
        type,
        {
          type,
          street: "",
          address_complement: null,
          "zip-code": "",
          city: "",
          state: null,
          "country-code": this.defaultCountryCode.toLowerCase(),
        },
        required,
      );
    },
    deleteField(group, type) {
      this[group] = this[group].filter(entry => entry.type !== type);
    },
    updatePhone(field, value) {
      this.$set(field, "value", value);
    },
    sortByRequired(entries) {
      entries.sort(p1 => (p1.required ? -1 : 1));
    },
    isRequiredType(group, type) {
      return this.forms[group].filter(entry => entry.type === type)[0].required;
    },
    onSubmit() {
      const attributes = {};

      if (this.forms?.details?.salutation)
        attributes.salutation = this.salutation;

      if (this.forms?.details?.lastname) attributes.lastname = this.lastname;

      if (this.forms?.details?.firstname) attributes.firstname = this.firstname;

      if (this.forms?.phones) attributes.phones = this.phones;

      if (this.forms?.addresses) attributes.addresses = this.addresses;

      if (this.forms?.personal) {
        attributes.marital_status = this.maritalStatus;
      }

      if (this.forms?.organization) {
        attributes.organization = {
          name: this.organizationName,
          industry: this.organizationIndustry,
          size: this.organizationSize,
        };
      }

      this.update(attributes);
    },
  },

  async mounted() {
    this.reset();
    await this.fetchMember();
    const {
      salutation,
      lastname,
      firstname,
      phones,
      addresses,
      marital_status,
    } = this.member || {
      marital_status: null,
      lastname: null,
      firstname: null,
      salutation: null,
      phones: [],
      addresses: [],
    };

    this.salutation = salutation;
    this.lastname = lastname;
    this.firstname = firstname;

    if (this.forms.personal) {
      this.marital_status = marital_status;
    }

    if (this.forms.organization) {
      this.organizationName = this.member?.organization?.name;
      this.organizationIndustry = this.member?.organization?.industry;
      this.organizationSize = this.member?.organization?.size;
    }

    for (const phone of phones) {
      if (this.getTypes("phones").includes(phone.type)) {
        this.phones.push({
          type: phone.type,
          value: phone.phone,
          required: this.isRequiredType("phones", phone.type),
        });
        this.$set(this.errors.phones, phone.type, null);
      }
    }
    this.sortByRequired(this.phones);

    for (const address of addresses) {
      if (this.getTypes("addresses").includes(address.type)) {
        const disabled =
          this.forms.addresses.filter(entry => entry.type === address.type)[0]
            .disabled || false;
        this.addresses.push({
          type: address.type,
          value: { ...address },
          required: this.isRequiredType("addresses", address.type),
          disabled: disabled,
        });
      }
    }
    this.sortByRequired(this.addresses);
    this.$root.$emit("my-account--informations:mounted");
  },
};
</script>
