<template>
  <div class="my-account-contact">
    <h1 class="super-title3">{{ $t("my-account.contact.title") }}</h1>
    <p
      class="contact-description text6"
      v-html="$t('my-account.contact.text')"
    />
    <form @submit.prevent="onSubmit" novalidate>
      <Alert variant="success" v-if="success">
        <span>{{ $t("my-account.contact.confirmation.text") }}</span>
      </Alert>
      <SelectInput
        :options="options"
        :value="service"
        label="Service"
        @update:value="selectService($event)"
        id="input--service"
      />
      <SelectInput
        v-if="subjects"
        :options="subjects"
        :value.sync="subject"
        label="Subject"
        id="input--subject"
      />
      <div v-if="type == 'form'">
        <TextArea
          id="input--message"
          :maxLength="maxLength"
          :value.sync="message"
          label="Message"
        />
        <ActivityIndicator v-if="submitting" />
        <Button
          v-else
          id="button--my-account-contact-form-submit"
          type="primary"
          nativeType="submit"
        >
          {{ $t("my-account.contact.form.buttons.submit") }}
        </Button>
      </div>
      <div v-else-if="type == 'call'">
        <p class="text1">
          <template v-if="subject">
            {{ $t(`my-account.contact.description.${service}.${subject}`) }}
          </template>
          <template v-else>
            {{ $t(`my-account.contact.description.${service}`) }}
          </template>
        </p>
        <Button
          v-if="conciergeNumber"
          id="button--my-account-contact-call"
          type="primary"
          @click="
            $root.$emit('my-account.contact.buttons.call:click', {
              number: conciergeNumber,
            })
          "
        >
          {{ $t("my-account.contact.buttons.call-concierge") }}<br />{{
            conciergeNumber
          }}
        </Button>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
.my-account-contact {
  h1 {
    @include setColor(--color-title1);
  }

  .contact-description {
    @include setColor(--color-text);
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { isString } from "@/utilities/validators";
import {
  ActivityIndicator,
  Alert,
  Button,
  SelectInput,
  TextArea,
} from "@johnpaul/jp-vue-components";
import { getConfig } from "@/store";
import { getModuleConfig } from "@/utilities/config";

export default {
  name: "MyAccountContact",

  components: {
    ActivityIndicator,
    Alert,
    Button,
    SelectInput,
    TextArea,
  },

  data() {
    const config = getConfig();
    const { options } = getModuleConfig(config, "my-account").modules.contact;
    return {
      services: options.form.services,
      maxLength: options.form.messageMaxLength || 100,

      service: null,
      subject: "",
      message: "",
      submitting: false,
      success: false,
    };
  },

  computed: {
    ...mapGetters({
      subscription: "subscriptions/primarySubscription",
    }),

    options() {
      return Object.keys(this.services).map(key => ({
        value: key,
        text: this.$t(`my-account.contact.inputs.services.${key}`),
      }));
    },
    subjects() {
      return this.service && !isString(this.services[this.service])
        ? Object.keys(this.services[this.service]).map(key => ({
            value: key,
            text: this.$t(
              `my-account.contact.inputs.subjects.${this.service}.${key}`,
            ),
          }))
        : null;
    },
    type() {
      return (
        (this.service &&
          this.subject &&
          this.services[this.service][this.subject]) ||
        this.services[this.service]
      );
    },

    conciergeNumber() {
      return this.subscription?.attributes?.specifics?.["service-phone"];
    },
  },

  methods: {
    selectService(service) {
      this.service = service;
      this.subject = null;
    },
    onSubmit() {
      this.success = true;
    },
  },
};
</script>
