import Vue from "vue";
import { sync } from "vuex-router-sync";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import PortalVue from "portal-vue";
import { BootstrapVue } from "bootstrap-vue";
import clonedeep from "lodash.clonedeep";

import { Tracker } from "@johnpaul/jp-tracker";
import instance, { client } from "@johnpaul/jp-api-client";

import "babel-polyfill";
import "core-js/features/array/flat";
import "intersection-observer";

// Styles
import "../sass/index.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { getStore, createStore, getConfig } from "./store";
import App from "./App";
import i18n from "./i18n";
import router, { getRoutes } from "./router";
import axios from "axios";
import { getApplicationConfig, getEnvironmentConfig } from "@/config";
import { getEnabledWidgets } from "@/utilities/config";
import { fetchResourcesAsJson } from "@/utilities/assetsLoader";
import { installATInternet } from "@/utilities/vendors";
import { registerFeatures } from "@/utilities/features";
import { requireModule } from "@/utilities/modules";
import { exit } from "@/utilities/autoLogout";
import { setHeadersAndInterceptors } from "@/plugins/jp-api-client";
import Logger from "./mixins/Logger";
import jwtDecode from "jwt-decode";

Vue.use(BootstrapVue);
Vue.use(PortalVue);
Vue.config.productionTip = false;

Vue.prototype.tracker = new Tracker();

function bootstrap(config, { container, debug, settings, environment, shouldEject }) {
  let store = getStore();
  let shouldRedirectToErrorPage = false;

  if (
    !config.modules ||
    (config.modules && !Object.keys(config.modules).length)
  ) {
    // If modules are empty, we should redirect user to the error page
    shouldRedirectToErrorPage = true;
  }

  // @deprecated, use add route
  getRoutes(config).forEach(r => {
    router.addRoute(r);
  });

  sync(store, router);

  // Extend global modules
  getEnabledWidgets(config).forEach(confModule => {
    Vue.component(confModule.name, requireModule(confModule.path, "widget"));
  });

  if (settings?.SENTRY_DSN) {
    Sentry.init({
      dsn: settings.SENTRY_DSN,
      environment: environment,
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true,
        }),
      ],
    });
  }

  const domId = container || "#app";
  const dom = document.querySelector(domId);
  if (!dom) {
    if (debug) {
      throw new Error("No container for app");
    }
    return;
  }
  const start = () => {
    new Vue({
      i18n,
      router,
      store,
      render: h => h(App),
    }).$mount(dom);
  };

  if (config.env.ATInternetID) {
    installATInternet({ id: config.env.ATInternetID }).then(start);
  } else {
    start();
  }

  if (shouldEject) {
    store.dispatch("setRedirectUrl", router.history?.pending?.fullPath);
    exit();
  } else if (shouldRedirectToErrorPage) {
    router.push({ name: "config-failure" });
    store.dispatch("setShouldFetchConfig", true);
  }
}

const initApp = (options, initialTokens, shouldEject) => {
  const {
    debug,
    clientId,
    container,
    platform,
    environment,
    settings,
    config,
    conductor,
  } = options;
  // Transform config (add env config and get enabled modules only)
  const completeConfig = getApplicationConfig(config, { platform });

  // set features object
  registerFeatures(completeConfig.features);

  // Store creation
  let store = createStore(completeConfig, initialTokens);

  // Add the headers and interceptors
  setHeadersAndInterceptors(
    store.state.auth[
      completeConfig.env.proxy ? "authenticated" : "accessToken"
    ],
    !!completeConfig.env.proxy,
  );

  // Store the final config and conductor in Vuex
  store.dispatch("setConfig", completeConfig).then(() => {
    store.dispatch("setConductorConfig", conductor).then(() => {
      if (debug) {
        Logger.log("Running on client" + clientId);
        Logger.log("Configuration options" + JSON.stringify(options));
      }
      bootstrap(completeConfig, { container, environment, settings, debug, shouldEject });
    });
  });

  // Clear previous persisted auth data in store (localStorage)
  if (
    completeConfig.env.proxy &&
    (store.state.auth.accessToken || store.state.auth.refreshToken)
  ) {
    store.state.auth.accessToken = null;
    store.state.auth.refreshToken = null;
  }
};

const fetchClientToRetrieveConfig = async (
  environmentConfig,
  accessToken,
  isDebug,
) => {
  const clientId = environmentConfig.clientId;
  const clientSecret = environmentConfig.clientSecret;
  try {
    const response = await client.auth.token(clientId, clientSecret, {
      grant_type: "client_credentials",
    });
    return response.data.access_token;
  } catch (error) {
    if (isDebug) {
      Logger.log("Error when trying to fetch client token");
      Logger.log(error);
    }
    return accessToken;
  }
};

const refreshTokenToRetrieveConfig = async (
  environmentConfig,
  accessToken,
  refreshToken,
  isDebug,
) => {
  try {
    const response = await client.auth.refreshToken(
      environmentConfig.clientId,
      environmentConfig.clientSecret,
      refreshToken,
    );
    return {
      accessTokenAfterRefresh: response.data.access_token,
      initialTokensAfterRefresh: {
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        refreshTokenExpiration: response.data.refresh_token_expires_in,
      },
    };
  } catch (error) {
    if (isDebug) {
      Logger.log("Error when trying to refresh token");
      Logger.log(error);
    }
    return {
      accessTokenAfterRefresh: accessToken,
      initialTokensAfterRefresh: {},
    };
  }
};

const fetchConfigFile = async (environmentConfig, accessToken, isDebug) => {
  try {
    const clientId = environmentConfig.clientId;
    const clientSecret = environmentConfig.clientSecret;
    if (environmentConfig.proxy) {
      instance.interceptors.request.use(request => {
        request.headers["x-client"] = `Basic ${btoa(
          `${clientId}:${clientSecret}`,
        )}`;
        return request;
      });
    }
    // Try to retrieve the config by calling the resources endpoint
    const res = await client.content.v2.resources.get(
      process.env.VUE_APP_CONFIG_FILENAME || "config",
      accessToken,
    );
    return {
      config: res?.data?.results?.[0]?.data,
      shouldEject: false,
    }
  } catch (error) {
    if (isDebug) {
      Logger.log("Error when trying to retrieve config");
      Logger.log(error);
    }
    // If retrieving member config failed with 401 or 403,
    // the member will be ejected and redirected to login page
    // so we try to load client config before
    if (axios.isAxiosError(error) && (error?.response?.status === 401 || error?.response?.status === 403)) {
      const newAccessToken = await fetchClientToRetrieveConfig(environmentConfig, accessToken, isDebug);
      const { config } = await fetchConfigFile(environmentConfig, newAccessToken, isDebug);
      return {
        config,
        shouldEject: true,
      };
    } else {
      // Else we load the embedded configFailure.json
      // This file contains the bare minimum to display an error page with pretty rendering
      try {
        return {
          config: fetchResourcesAsJson("/configFailure.json"),
          shouldEject: false,
        };
      } catch (error) {
        if (isDebug) {
          Logger.log("Error when trying to load configFailure");
          Logger.log(error);
        }
        return { config: {}, shouldEject: false };
      }
    }
  }
};

const companion = async function(optionData) {
  let initialTokens = {};
  let options = clonedeep(optionData);
  let { debug } = options;

  // Load embedded conductor
  const conductorResponse = await client.get("/conductor.json");
  // Get environment configurations
  const environmentConfig = getEnvironmentConfig(options.platform);
  // Set base URL for all requests
  instance.defaults.baseURL = environmentConfig.apiUrl;

  // Get Vuex store from the localstorage
  const vuexStorage = window.localStorage.getItem("vuex")
    ? JSON.parse(window.localStorage.getItem("vuex"))
    : {};

  let accessToken = vuexStorage?.auth?.accessToken;

  // We should try to fetch a new client token when user is unlogged
  const shouldFetchClientToken = environmentConfig.proxy
    ? !vuexStorage?.auth?.authenticated
    : !vuexStorage?.auth?.accessToken;

  // We have to fetch client token before trying to retrieve config
  if (shouldFetchClientToken) {
    accessToken = await fetchClientToRetrieveConfig(
      environmentConfig,
      accessToken,
      debug
    );
  }

  if (
    !environmentConfig.proxy &&
    vuexStorage?.auth?.accessToken &&
    vuexStorage?.auth?.refreshToken &&
    vuexStorage?.auth?.refreshTokenExpiration
  ) {
    const isExpired = time => {
      return Date.now() > time * 1000;
    };
    const jwt = jwtDecode(accessToken);
    const accessTokenExpired = isExpired(jwt.exp);
    const refreshTokenExpired = isExpired(
      jwt.iat + vuexStorage?.auth?.refreshTokenExpiration,
    );
    const shouldRefreshAccessToken = accessTokenExpired && !refreshTokenExpired;

    if (shouldRefreshAccessToken) {
      const {
        accessTokenAfterRefresh,
        initialTokensAfterRefresh,
      } = await refreshTokenToRetrieveConfig(
        environmentConfig,
        accessToken,
        vuexStorage.auth.refreshToken,
        debug,
      );
      accessToken = accessTokenAfterRefresh;
      initialTokens = initialTokensAfterRefresh;
    }
  }

  const { config, shouldEject } = await fetchConfigFile(environmentConfig, accessToken, debug);

  options.config = config;
  options.conductor = conductorResponse.data;

  if (debug) {
    Logger.log("Starting companion with options :");
    Logger.log(options);
  }

  initApp(options, initialTokens, shouldEject);
};

// set Companion object
window.Companion = {
  VERSION: process.env.VUE_APP_VERSION,
  features: null,
};
// inject version here
companion.VERSION = process.env.VUE_APP_VERSION;

export default companion;
