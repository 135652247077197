<template>
  <div class="vendors-list">
    <CollectionFilters
      v-if="collection && (enabledFilters.length || showSearch)"
      :collectionName="collectionName"
      :enabledFilters="enabledFilters"
      :showSearch="showSearch"
      :moduleName="moduleName"
    />
    <b-container
      :class="[
        'vendors-content',
        (enabledFilters.length || showSearch) && 'with-filters',
      ]"
    >
      <template v-if="collection">
        <b-row>
          <b-col>
            <h1 class="super-title3 mt-3">{{ $t(`${moduleName}.title`) }}</h1>
          </b-col>
        </b-row>

        <b-row
          v-if="
            fetching || (collection.components && collection.components.length)
          "
        >
          <b-col
            class="card-wrapper"
            cols="12"
            md="4"
            v-for="(component, index) in collection.components"
            :key="`component-${index}`"
          >
            <OfferCardLink
              :component="component"
              :in-viewport-active="true"
              :in-viewport-once="true"
              @display="trackDisplay(index)"
              @mousedown.native="trackClick(index)"
            />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <SearchNoResult
              class="no-result"
              :module-name="moduleName"
              :collection="collection"
              :show-concierge-link="showConciergeLink"
            />
          </b-col>
        </b-row>
      </template>
      <ActivityIndicator v-if="fetching" />
    </b-container>
  </div>
</template>

<style lang="scss">
.vendors-list {
  margin-top: 0 !important;
  padding-top: 0 !important;
  position: relative;

  h1 {
    @include setColor(--color-title1);
  }

  .card-wrapper {
    display: flex;
  }

  .offer-card-link {
    flex: 1;
    margin-bottom: rem(15px);

    @include respond-min("medium") {
      margin-bottom: rem(34px);
    }
  }

  .offer-card {
    height: 100%;
  }
}
</style>

<script>
import { mapState } from "vuex";

import { ActivityIndicator } from "@johnpaul/jp-vue-components";
import OfferCardLink from "@/components/OfferCardLink";
import CollectionFilters from "@/components/filters/CollectionFilters";
import SearchNoResult from "@/components/filters/SearchNoResult";

import contentPageMixin from "@/mixins/contentPageMixin";

import { getModuleConfig } from "@/utilities/config";
import {
  getComponentVendorId,
  getComponentBenefitId,
  getFilterValues,
} from "@/utilities/tracking";
import { getConfig } from "@/store";

export default {
  components: {
    ActivityIndicator,
    CollectionFilters,
    OfferCardLink,
    SearchNoResult,
  },

  mixins: [contentPageMixin],

  data() {
    const config = getConfig();
    const moduleConfig = getModuleConfig(config, this.moduleName);

    return {
      enabledFilters: moduleConfig.options.filters || [],
      pageId: moduleConfig.options.pageId,
      showSearch: moduleConfig.options.search,
      showConciergeLink: moduleConfig.options.conciergeLink,
    };
  },

  props: {
    moduleName: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      memberId: state => state.member.data?.id || null,
      fetching: state => state.content.fetchingComponents,
    }),

    collectionName() {
      return this.pageCollections?.[0]?.slug;
    },

    collection() {
      return this.collections?.[this.collectionName];
    },
  },

  methods: {
    trackFilterLayer() {
      this.$root.$emit(`${this.moduleName}.list.filters.filters:open`);
    },

    getPublisherParams(idx) {
      const category = this.collection.filters?.find(f => f.key === "family");
      const component = this.collection.components[idx];
      const benefitId = getComponentBenefitId(component);
      return {
        category: getFilterValues(category),
        benefitId,
        positionItem: idx + 1,
        nbItems: this.collection.count,
        memberId: this.memberId,
        vendorId: getComponentVendorId(component),
        url: encodeURIComponent(window.location.toString()),
      };
    },

    trackClick(idx) {
      this.$emit("publisher-click", this.getPublisherParams(idx));
    },

    trackDisplay(idx) {
      this.$emit("publisher-display", this.getPublisherParams(idx));
    },
  },

  mounted() {
    if (!this.enabledFilters.length && !this.showSearch) {
      this.$root.$emit(`${this.moduleName}.list.all:mounted`);
    }
  },
};
</script>
