<template>
  <div
    :class="[
      'jp-header',
      hasTransparentHeader && 'transparent',
      hasShadow && 'has-shadow',
      navbarOpened && 'navbar-open',
    ]"
  >
    <FontIcon
      v-if="!shouldFetchConfig"
      :class="['menu-toggle-icon', 'd-xl-none', navbarOpened && 'd-none']"
      name="ui_burger"
      @click.native="navbarOpened = !navbarOpened"
    />

    <img
      id="link--header-link-logo"
      :src="logo"
      class="logo"
      alt="logo"
      @click="onLogoClick"
    />

    <div
      v-if="hasLogoBackground"
      v-show="!hasTransparentHeader"
      class="logo-background"
      :style="`background-image: url('${logoBackground}')`"
    ></div>

    <template v-if="!shouldFetchConfig">
      <AccountMenu
        v-if="loggedIn"
        :transparent="hasTransparentHeader"
        @logout="$emit('logout')"
      />

      <!-- Burger menu + overlay -->
      <div class="menu-overlay" @click="navbarOpened = false" />
      <div class="header-menu">
        <FontIcon
          name="ui_close"
          class="close-icon d-xl-none"
          @click.native="navbarOpened = false"
        />

        <div class="scrollable-content">
          <AccountCard v-if="loggedIn" class="d-xl-none" />

          <ConnectionButtons :transparent="hasTransparentHeader" v-else />

          <PagesNav v-if="loggedIn" class="pages-nav" />

          <LegalNav class="header-secondary-nav d-xl-none" sideNav />

          <LanguageSwitcher
            v-if="locales.length > 1"
            class="language-switcher d-xl-none"
            :options="
              locales.map(language => ({
                value: language,
                text: this.$t(`common.languages.${language}`),
              }))
            "
            :locale="locale"
            @update:locale="setLocale"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
$animationDuration: 200ms;

.jp-header {
  position: relative; // ie11 fallback
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: rem(72px);
  @include setColor(--color-background1, background-color);
  transition: $animationDuration all ease;
  z-index: 1040;

  &.has-shadow {
    box-shadow: 0px 0px 7px rgba(74, 96, 117, 0.17);
  }

  .logo {
    max-height: rem(45px);
    cursor: pointer;
    z-index: 10;

    &-background {
      position: absolute;
      left: rem(-80px);
      z-index: 0;
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;

      @include respond-to("xlarge") {
        display: none;
      }
    }

    img {
      max-height: rem(65px);
    }

    @include respond-min("xlarge") {
      margin: rem(0 26px);
    }
  }

  .menu-toggle-icon {
    padding: rem(16px);
    font-size: rem(22px);
    @include setColor(--color-cta);
  }

  &.transparent .menu-toggle-icon {
    @include setColor(--color-cta-negative);
  }

  .close-icon {
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    font-size: rem(20px);
    z-index: 1;
    opacity: 0;
    transition: $animationDuration opacity ease;
    @include setColor(--color-cta);
  }

  &.navbar-open .close-icon {
    opacity: 1;
  }

  @include respond-to("xlarge") {
    height: rem(56px);

    .header-menu {
      position: fixed;
      top: 0;
      left: -85%;
      width: 85%;
      max-width: rem(350px);
      height: 100%;
      @include setColor(--color-background1, background-color);
      transition: $animationDuration left ease;
      z-index: 1050;
    }

    .menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1045;
      pointer-events: none;
      opacity: 0;
      transition: $animationDuration opacity ease;
    }

    &.navbar-open {
      .header-menu {
        display: block;
        left: 0;
      }

      .menu-overlay {
        opacity: 1;
        pointer-events: initial;
      }
    }

    .logo {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      bottom: 50%;
    }

    .scrollable-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: rem(43px 32px 10px);
      overflow-y: auto;
    }

    .pages-nav {
      padding: rem(35px) 0;
      @include setColor(--color-text);
    }

    .header-secondary-nav {
      display: flex;
      flex-direction: column;
      padding: rem(35px) 0;
      @include setColor(--color-text);

      border-width: 0;
      border-style: solid;
      border-top-width: 1px;
      border-bottom-width: 1px;
      @include setColor(--color-divider, border-color);
    }

    .language-switcher {
      margin: 35px 0 0;
    }
  }

  &.transparent {
    background-color: transparent;
    box-shadow: none;

    @include respond-min("xlarge") {
      .nav-item {
        @include setColor(--color-text-negative, color, true);

        &::after {
          @include setColor(--color-text-negative, border-bottom-color);
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import scrollMixin from "@/mixins/scrollMixin";

import { FontIcon, LanguageSwitcher } from "@johnpaul/jp-vue-components";

import LegalNav from "../LegalNav";
import PagesNav from "./PagesNav";
import ConnectionButtons from "./ConnectionButtons";
import AccountCard from "./AccountCard";
import AccountMenu from "./AccountMenu";

export default {
  components: {
    AccountCard,
    AccountMenu,
    ConnectionButtons,
    FontIcon,
    LanguageSwitcher,
    LegalNav,
    PagesNav,
  },

  mixins: [scrollMixin],

  data() {
    return {
      accountMenuOpened: false,
      navbarOpened: false,
      hideAccountMenu: false,
    };
  },

  props: {
    positionLogo: {
      type: String,
      default: "left",
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      loggedIn: "auth/loggedIn",
    }),

    ...mapState({
      config: state => state.config,
      isMobileApp: state => state.isMobileApp,
      locale: state => state.i18n.locale,
      shouldFetchConfig: state => state.shouldFetchConfig,
    }),

    locales() {
      return this.config.options.locales;
    },
    hasLogoBackground() {
      return this.config.options.hasLogoBackground;
    },
    logo() {
      return this.hasTransparentHeader
        ? this.config.global?.header?.logoNegative
        : this.config.global?.header?.logo;
    },
    logoBackground() {
      return this.config.global?.header?.logoBackground;
    },
    hasTransparentHeader() {
      return this.transparent && this.scrollY <= 0;
    },
    hasShadow() {
      return this.shadow || this.scrollY > 0;
    },
    logoEventName() {
      return this.loggedIn ? "logged-logo:click" : "unlogged-logo:click";
    },
  },

  watch: {
    $route() {
      this.navbarOpened = false;
    },
  },

  methods: {
    ...mapActions({
      setLocale: "i18n/setLocale",
    }),

    onLogoClick() {
      this.$root.$emit(this.logoEventName);
      if (this.shouldFetchConfig) {
        window.location.reload();
      }
    },
  },
};
</script>
