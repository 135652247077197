<template>
  <form
    @submit.prevent="onSubmit"
    @reset.prevent="onReset"
    novalidate
    class="dynamic-form"
    autocomplete="off"
  >
    <b-container>
      <b-row>
        <b-col>
          <slot name="header" />
        </b-col>
      </b-row>
      <b-row v-for="(row, i) in form.fields" :key="`row-${i}`">
        <template v-for="(field, fieldName) in row">
          <b-col
            v-if="transformedFields[fieldName].visible !== false"
            :key="`col-${fieldName}`"
            :style="getColumnStyle(field)"
          >
            <div class="text6 title" :class="titleClass" v-if="field.title">
              {{ $t(field.title) }}
            </div>
            <AddressInput
              v-if="
                transformedFields &&
                  transformedFields[fieldName].type === 'address'
              "
              v-bind="getFieldProps(fieldName)"
              @update:value="
                resetError(fieldName);
                field.value = $event;
              "
              @blur="checkField(fieldName)"
            />
            <ParticipantsInput
              v-else-if="
                transformedFields &&
                  transformedFields[fieldName].type === 'participants'
              "
              v-bind="getFieldProps(fieldName)"
              @update:value="field.value = $event"
            />
            <DynamicInput
              v-else
              :type="transformedFields[fieldName].type"
              :inputProps="getFieldProps(fieldName)"
              @update:value="
                resetError(fieldName);
                field.value = $event;
              "
              @blur="checkField(fieldName)"
            />
          </b-col>
        </template>
      </b-row>
      <slot />
      <b-row :class="btnContainerClass">
        <b-col v-if="cancelText">
          <ActivityIndicator v-if="submitting" />
          <Button
            :id="`${buttonId}-cancel`"
            type="secondary"
            nativeType="reset"
            v-else
          >
            {{ cancelText }}
          </Button>
        </b-col>
        <b-col>
          <ActivityIndicator v-if="submitting" />
          <Button
            :id="buttonId"
            class="text6"
            type="primary"
            nativeType="submit"
            v-else
            :disabled="!canBeSubmitted()"
          >
            {{ buttonText }}
          </Button>
        </b-col>
      </b-row>
    </b-container>
  </form>
</template>

<style lang="scss">
.dynamic-form {
  .title {
    margin-bottom: rem(12px);
  }

  a {
    @include setColor(--color-link);
  }
}
</style>

<script>
import { mapState } from "vuex";

import validators from "@/utilities/validators";
import transformUtils from "@/utilities/transforms";
import parsers from "@/utilities/formParsers";

import AddressInput from "@/components/AddressInput";
import ParticipantsInput from "@/components/ParticipantsInput";
import {
  ActivityIndicator,
  Button,
  DynamicInput,
} from "@johnpaul/jp-vue-components";

export default {
  name: "DynamicForm",

  components: {
    ActivityIndicator,
    AddressInput,
    Button,
    DynamicInput,
    ParticipantsInput,
  },

  data() {
    return {
      errors: {},
      skipInitFromTransform: false,
    };
  },

  props: {
    prefixInputId: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      default: null,
    },
    btnContainerClass: {
      type: String,
      default: "",
    },
    titleClass: {
      type: String,
      default: null,
    },
    moduleState: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState({
      config: state => state.config,
      locale: state => state.i18n.locale,
    }),

    fields() {
      return this.form.fields.reduce((acc, val) => {
        return { ...acc, ...val };
      }, {});
    },

    transformedFields() {
      return Object.entries(this.fields).reduce(
        (acc, [name, field]) => ({
          ...acc,
          [name]: this.transformField(field, acc),
        }),
        this.fields,
      );
    },
    buttonId() {
      return (
        "button-" + (this.prefixInputId && `-${this.prefixInputId}`) + "-submit"
      );
    },
  },

  methods: {
    transformField(field, fields) {
      const fieldTransform = [
        ...(field.transform ? field.transform : []),
        { name: "translateLabels" },
      ];

      return fieldTransform.reduce((fieldToTransform, transform) => {
        if (fieldToTransform.policy) {
          fieldToTransform.policies = this.config.options.policies[field.policy.type];
        }
        const { name, ...options } = transform;
        const transformFunc = transformUtils[name] || (() => void 0);
        if (this.skipInitFromTransform && name === "initFromMember") {
          return fieldToTransform;
        }
        const transformOptions = {
          ...options,
          state: this.moduleState,
          i18nVariant: this.form.i18nVariant,
        };

        return transformFunc
          ? transformFunc(fieldToTransform, transformOptions, fields)
          : fieldToTransform;
      }, field);
    },

    getColumnStyle(field) {
      if (!field.width) return;
      const percentage = `${field.width * 100}%`;
      return {
        "min-width": percentage,
        "max-width": percentage,
      };
    },

    getFieldProps(fieldName) {
      const field = this.transformedFields[fieldName];

      // eslint-disable-next-line no-unused-vars
      const { transform, visible, ...props } = field;

      if (field.policy) {
        field.policy.text = this.$t(field.policy.text);
      }

      const prefixId =
        "input-" + (this.prefixInputId && `-${this.prefixInputId}`);

      return {
        ...props,
        id: `${prefixId}-${fieldName}`,
        disabled: this.submitting,
        locale: this.locale,
        error: this.errors[fieldName],
      };
    },

    getApiValues() {
      const formParsers = this.form?.parsers || [];
      formParsers.push({ name: "parseApiKeys" });

      return formParsers.reduce((apiValues, parser) => {
        const parserFunction = parsers[parser.name] || (() => null);
        return {
          ...apiValues,
          ...parserFunction(this.transformedFields, parser),
        };
      }, this.form.defaultApiValues);
    },

    canBeSubmitted() {
      const fields = Object.values(this.transformedFields);
      for (const field of fields) {
        if (this.getErrorField(field)) return false;
      }
      return true;
    },

    checkField(fieldName) {
      const field = this.transformedFields[fieldName];
      this.$set(this.errors, fieldName, this.getErrorField(field));
    },

    getErrorField(field) {
      const { required, value } = field;
      if (required) {
        if (validators.isBoolean(value)) {
          if (value === false) return "required";
        } else if (!value) {
          return this.$t("common.form.input.error.missing");
        }
      }
      if (field.validators) {
        for (const validatorName of field.validators) {
          const isValid = validators[validatorName](field.value);
          if (!isValid)
            return this.$t(
              `common.form.input.validators.${validatorName}.error`,
            );
        }
      }
      return null;
    },

    resetError(fieldName) {
      this.$set(this.errors, fieldName, null);
    },

    onSubmit() {
      this.skipInitFromTransform = true;
      this.$emit("submit", this.getApiValues());
      this.skipInitFromTransform = false;
    },

    onReset() {
      this.$emit("reset");
    },
  },

  created() {
    for (const fieldName in this.transformedFields) {
      this.$set(this.errors, fieldName, null);
    }
  },
};
</script>
