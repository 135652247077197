<template>
  <b-navbar-nav class="ml-auto text1" align="right">
    <b-nav-item-dropdown
      right
      :class="['account-menu', transparent && 'transparent']"
    >
      <template v-slot:button-content>
        <AccountCard class="header-account-card d-none d-xl-inline-block" />
        <FontIcon :name="accountIcon" class="account-icon d-none d-xl-block" />
        <FontIcon name="ui_profil" class="account-icon d-xl-none" />
      </template>

      <template v-for="link in links">
        <b-dropdown-item :key="link.name">
          <EventLink
            :id="`link--header-account-link-${link.name}`"
            class="account-menu-item text8"
            :event="`menu.links.${link.name}:click`"
          >
            <FontIcon class="account-item-icon" :name="link.icon" />
            <span>{{ $t(`common.header.links.${link.name}`) }}</span>
          </EventLink>
        </b-dropdown-item>
      </template>

      <a
        class="account-menu-item text8"
        id="link--header-account-link-logout"
        @click="$emit('logout')"
      >
        <FontIcon class="account-item-icon" name="ui_log" />
        <span>{{ $t("common.header.links.logout") }}</span>
      </a>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<style lang="scss">
.account-menu {
  font-size: rem(25px);
  align-items: center;
  padding-right: rem(15px);

  .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0 rem(16px) 0 0;
    @include setColor(--color-cta);

    &:active,
    &:focus {
      outline: 0px solid transparent;
    }

    &::after {
      display: none;
    }

    .account-icon {
      @include setColor(--color-cta);
    }
  }

  &.transparent {
    .dropdown-toggle,
    .account-icon {
      @include setColor(--color-cta-negative);
    }

    .header-account-card {
      @include setColor(--color-text-negative);
    }
  }

  .dropdown-menu {
    transform: none !important;
    top: rem(40px) !important;
    left: auto !important;
    right: 30px !important;
    min-width: auto;
    padding: 0;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.16);

    @include respond-to("xlarge") {
      top: rem(35px) !important;
      left: auto !important;
      right: 0 !important;
    }
  }

  .account-toggle-icon {
    font-size: rem(22px);
  }

  .header-account-card {
    margin-right: rem(24px);
    display: inline-block;
    @include setColor(--color-text);
  }

  .dropdown-item {
    padding: 0;
  }

  .account-menu-item {
    height: rem(41px);
    display: flex;
    align-items: center;
    padding-right: rem(30px);
    @include setColor(--color-text);
    text-transform: none;

    &:hover {
      text-decoration: none;
      @include setColor(--color-text);
      @include setColor(--color-background2, background-color);
    }
  }

  .account-item-icon {
    font-size: rem(16px);
    padding: rem(0 10px 0 14px);
    margin-right: rem(5px);
  }
}
</style>

<script>
import { FontIcon } from "@johnpaul/jp-vue-components";
import EventLink from "@/components/EventLink";
import AccountCard from "./AccountCard";
import { getConfig } from "@/store";

export default {
  components: {
    EventLink,
    AccountCard,
    FontIcon,
  },

  data() {
    const config = getConfig();
    return {
      links: config.options?.accountLinks,
      accountIcon: config.options?.accountIcon || "ui_profil",
    };
  },

  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
