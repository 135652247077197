<template>
  <section class="card-member-template">
    <div
      :style="{ backgroundImage: backgroundImage }"
      class="card-member-template__banner"
    />
    <b-container class="card-member-template__wrapper">
      <b-row>
        <b-col>
          <h1 class="card-member-template__title super-title3 mt-3">
            {{ title }}
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="card-member-template__card-wrapper">
            <div class="card-member-template__card">
              <div class="card-member-template__card-background">
                <img :src="assets.cardImageRecto" alt="" />
              </div>
              <div class="card-member-template__card-content">
                <span class="card-member-template__name">
                  {{ fullName }}
                  <br />
                  {{ company }}
                </span>
                <span
                  v-if="formattedEndDate"
                  class="card-member-template__date"
                >
                  {{ formattedEndDate }}
                </span>
              </div>
            </div>
            <div class="card-member-template__card">
              <div class="card-member-template__card-background">
                <img :src="assets.cardImageVerso" alt="" />
              </div>
              <div class="card-member-template__card-content">
                <span class="card-member-template__card-id">
                  {{ externalId }}
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="description">
        <b-col>
          <div class="card-member-template__description" v-html="description" />
        </b-col>
      </b-row>
      <b-row v-if="addingPassError">
        <b-col>
          <Alert variant="error">
            <span>{{ $t("common.card-member.download.pkpass.error") }}</span>
          </Alert>
        </b-col>
      </b-row>
      <b-row v-if="showPdfButton" class="card-member-template__download">
        <b-col>
          <div class="card-member-template__download-description">
            {{ $t("common.card-member.download.description.desktop") }}
          </div>
          <ActivityIndicator v-if="isFetchingPass" />
          <form
            v-else
            method="post"
            @submit="$emit('submitPdfPassForm', $event)"
          >
            <input type="hidden" name="token" />
            <button
              :id="`button--card-member--pdf-download`"
              class="card-member-template__button text6 button button-primary"
            >
              {{ pdfDownloadButtonLabel }}
            </button>
          </form>
        </b-col>
      </b-row>
      <b-row
        v-if="showAppleWalletButton"
        class="card-member-template__download"
      >
        <b-col>
          <div class="card-member-template__download-description">
            {{ appletWalletDescription }}
          </div>
          <ActivityIndicator v-if="isFetchingPass" />
          <form
            v-else
            method="post"
            @submit="$emit('submitPkPassForm', $event)"
          >
            <input type="hidden" name="token" />
            <button
              id="button--card-member--pkpass-download"
              class="card-member-template__pkpass-button"
            >
              <img
                :src="assets[`appleWallet-${locale}`]"
                alt="Apple Wallet download button"
                class="card-member-template__download-apple"
              />
            </button>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<style lang="scss">
.card-member-template {
  min-height: 96vh;
  padding: rem(24px) 0 rem(48px) 0;

  &__banner {
    display: none;
    height: rem(315px);
    background-position: center;
    background-size: cover;
  }

  & h1 {
    @include setColor(--color-title1);
  }

  &__wrapper {
    margin: 0 auto;
  }

  &__title {
    word-break: break-word;
  }

  &__download {
    flex-direction: column;
    text-align: center;

    &-description {
      margin: rem(48px) 0 rem(16px);
    }

    &-apple {
      transform-origin: top;
      transform: scale(1.5);
    }
  }

  &__description {
    margin: rem(48px) 0 rem(16px);
    text-align: center;
  }

  &__button {
    display: inline-flex;
    width: auto !important;
    margin: auto;
  }

  &__card {
    position: relative;
    margin: rem(16px) 0;

    &-background {
      position: relative;
      z-index: 1;

      & img {
        width: 100%;
        display: block;
        height: auto;
      }
    }

    &-content {
      position: absolute;
      z-index: 5;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 8% 6%;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &__name {
    position: absolute;
    width: 78%;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    bottom: 9%;
    left: 6%;
    text-align: left;
    line-height: 1.2;
  }

  &__date {
    position: absolute;
    width: 60px;
    font-size: 16px;
    color: #fff;
    bottom: 9%;
    right: 6.5%;
    text-align: right;
    line-height: 18px;
  }

  &__card-id {
    position: absolute;
    width: 100%;
    font-size: 16px;
    color: #fff;
    top: 38%;
    left: 0;
    text-align: center;
    line-height: 1.5;
    text-transform: uppercase;
  }

  &__pkpass-button {
    border: none;
  }

  .jp-alert.alert--error {
    margin-top: rem(16px);
    margin-bottom: 0;
  }

  @include respond-min("medium") {
    padding: 0;

    &__banner {
      display: block;
    }

    &__wrapper {
      padding-bottom: 75px;
    }

    &__card {
      margin: 0 rem(24px);

      &-wrapper {
        flex-direction: row;
        justify-content: space-around;
      }
    }

    &__button {
      position: relative;
      left: auto;
      bottom: auto;
      padding: rem(0 50px) !important;
    }
  }
}
</style>

<script>
import { ActivityIndicator, Alert } from "@johnpaul/jp-vue-components";
import { isAppleDevice } from "@/utilities/mobile";
import { getCloudinaryResponsiveUrl } from "@/utilities/images";
import { MediaType } from "@/constants/media";
import {mapState} from "vuex";

export default {
  components: {
    Alert,
    ActivityIndicator,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    isMobileApp: {
      type: Boolean,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    externalId: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    isFetchingPass: {
      type: Boolean,
      default: false,
    },
    addingPassError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      config: state => state.config,
    }),
    pdfDownloadButtonLabel() {
      return this.isMobileApp
        ? this.$t("common.card-member.download.android")
        : this.$t("common.card-member.download.desktop");
    },
    showPdfButton() {
      return !(this.isMobileApp && window?.device?.platform === "iOS");
    },
    showAppleWalletButton() {
      return (
        isAppleDevice() ||
        (this.isMobileApp && window?.device?.platform === "iOS")
      );
    },
    appletWalletDescription() {
      return this.isMobileApp
        ? this.$t("common.card-member.download.description.apple.mobile")
        : this.$t("common.card-member.download.description.apple.desktop");
    },
    optimizedImageUrl() {
      return getCloudinaryResponsiveUrl(this.imageUrl, MediaType.DETAIL);
    },
    backgroundImage() {
      return "url('" + this.optimizedImageUrl + "')";
    },
    formattedEndDate() {
      const month = this.endDate?.slice(5, 7);
      const year = this.endDate?.slice(0, 4);
      return month && year ? month + "/" + year : null;
    },
    assets() {
      return this.config?.assets || {};
    },
  },
};
</script>
