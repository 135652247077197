<template>
  <div :class="['concierge-header text-center', lightBackground && 'light']">
    <FontIcon
      v-if="!vendor && backButton"
      class="nav-icon back-icon"
      @mousedown.prevent
      @click.native="$emit('back-click')"
      name="ui_chevron_left"
    />
    <PhoneLink
      v-else-if="conciergeNumber"
      id="button--concierge-phone"
      class="nav-icon phone"
      :number="conciergeNumber"
    />
    <div class="nav-icon close-icon">
      <FontIcon
        name="ui_close"
        @mousedown.native.prevent
        @click.native="$emit('close-click')"
      />
    </div>
    <div class="title text5">
      {{ headerTitle }}
    </div>
  </div>
</template>

<style lang="scss">
.concierge-header {
  position: relative;
  height: rem(64px);
  min-height: rem(64px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include setColor(--color-background4, background-color);

  .title {
    margin: rem(0 50px);
    position: relative;
    @include setColor(--color-title1-negative);
  }

  .nav-icon {
    position: absolute;
    @include setColor(--color-cta-negative);
    text-decoration: none;
    top: 50%;
    transform: translateY(-50%);

    &.phone {
      left: rem(24px);

      a {
        @include setColor(--color-cta-negative, color, true);
        height: rem(22px);
        font-size: rem(22px);
        display: block;
        text-decoration: none;
      }
    }

    &.back-icon {
      left: rem(24px);
      font-size: 30px;
      height: 30px;
      cursor: pointer;
    }

    &.close-icon {
      position: absolute;
      right: rem(24px);
      left: auto;
      font-size: rem(22px);
      line-height: rem(60px);
      @include setColor(--color-cta-negative);

      > * {
        cursor: pointer;
      }
    }
  }

  &.light {
    @include setColor(--color-background1, background-color);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.103065);

    .title {
      @include setColor(--color-title1);
    }

    .nav-icon {
      @include setColor(--color-cta);
    }
  }
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

import { FontIcon, PhoneLink } from "@johnpaul/jp-vue-components";
import { getConfig } from "@/store";
import { getModuleConfig } from "@/utilities/config";

export default {
  components: { FontIcon, PhoneLink },

  props: {
    backButton: {
      type: Boolean,
      default: true,
    },
    lightBackground: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      categoryName: state => state.request.category?.name,
      vendor: state => state.request.vendor,
    }),

    ...mapGetters({
      subscription: "subscriptions/primarySubscription",
    }),

    conciergeNumber() {
      const config = getConfig();
      const moduleConfig = getModuleConfig(config, "request/concierge");
      return moduleConfig.options.home.data.phone.enabled ? this.subscription?.attributes?.specifics?.["service-phone"] : null;
    },

    headerTitle() {
      if (this.vendor) {
        return this.vendor.name;
      }

      return this.categoryName
        ? this.$t(`request.concierge.header.category.${this.categoryName}`)
        : this.$t("request.concierge.header.title");
    },
  },
};
</script>
